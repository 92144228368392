.frontend-footer {
  text-align: center;
  color: #1D1D1D;
  clear: both;
  background-color: #FFFFFF;
  width: 100%;
  font-size: 14px;
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: center;
  border-top: 0.25px solid #707070;
  box-shadow: 0px 3px 6px #00000029;
}
.frontend-footer img {
  vertical-align: middle;
  margin: 0 0.5em 0 0;
  height: auto;
  width: 26.7px;
}
@media (max-width: 1024px) {
  .frontend-footer {
    font-size: 12px;
  }
}
