.reactivate-app p {
  margin: 40px 0;
}
.modal-content .container-box .form-sections .form-inner-section .form-inner-elements .extension-text {
  font-size: 14px;
}
@media (max-width: 910px) {
  .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .extension-text {
    font-size: 12px;
    padding: 0;
    padding-right: 1rem;
  }
}
.modal-content .payment-statuses-index {
  max-height: 80vh;
  overflow: auto;
}
.modal-content .payment-statuses-index .responsive-table-container {
  width: 99%;
}
.modal-content .payment-statuses-index .responsive-table-row .responsive-table-details {
  font-size: 14px;
}
@media (min-width: 896px) {
  .modal-content .payment-statuses-index .responsive-table-row .responsive-table-details {
    padding: 0.5em;
  }
}
.modal-content .payment-statuses-index .responsive-table-row .responsive-table-details span {
  font-family: 'Roboto-Regular';
  color: #98989A;
}
@media (min-width: 896px) {
  .modal-content .payment-statuses-index .responsive-table-row .responsive-table-details:nth-child(1) {
    width: 10%;
  }
  .modal-content .payment-statuses-index .responsive-table-row .responsive-table-details:nth-child(2) {
    width: 33%;
  }
  .modal-content .payment-statuses-index .responsive-table-row .responsive-table-details:nth-child(3) {
    width: 57%;
  }
}
.app-overview-container {
  display: flex;
  flex-direction: column;
  padding: 1em;
  max-width: 1030px;
  min-height: 160px;
  margin-bottom: 10px;
  border: 1px solid #DEDEDE;
  margin: 2em 0;
}
.app-overview-container .right-arrow {
  padding-left: 0.3em;
}
.app-overview-container h3 {
  margin: 0.5em 0 0.5em 0.5em;
  font-family: 'Roboto-Medium';
  font-weight: normal;
}
.app-overview-container h4 {
  font-family: 'Roboto-Medium';
  font-weight: normal;
}
.app-overview-container .app-overview-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 1em;
  flex-direction: column;
}
.app-overview-container .app-overview-top .tab-section {
  width: 100%;
  display: flex;
  justify-content: center;
}
.app-overview-container .app-overview-top .tab-section .mat-tabs-container .MuiAppBar-colorDefault {
  max-width: unset;
}
.app-overview-container .app-overview-top .tab-section .mat-tabs-container .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer button {
  width: 25%;
}
@media (max-width: 600px) {
  .app-overview-container .app-overview-top .tab-section {
    width: 80vw;
  }
}
.app-overview-container .app-overview-top .applications-overview-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.app-overview-container .app-overview-top .applications-overview-left .seperator-tall {
  min-height: 25px;
}
.app-overview-container .app-overview-top .applications-overview-left .statuses-index {
  text-decoration: underline;
  color: #048041;
  cursor: pointer;
}
.app-overview-container .app-overview-top .applications-overview-left .app-stage-tabs .MuiTab-wrapper {
  padding: 0.6em;
}
.app-overview-container .app-overview-top .applications-overview-left h3 {
  min-width: 240px;
}
@media (max-width: 600px) {
  .app-overview-container .app-overview-top .applications-overview-left {
    flex-direction: column;
  }
  .app-overview-container .app-overview-top .applications-overview-left .separator {
    min-height: 0;
    width: 50%;
    height: 0;
    border: 0.5px solid #e3ddda;
  }
  .app-overview-container .app-overview-top .applications-overview-left h3 {
    font-size: 1.8em;
    width: 90%;
    text-align: center;
  }
}
.app-overview-container .app-overview-top .applications-overview-left .application-cat-selection {
  width: 350px;
}
.app-overview-container .app-overview-top .applications-overview-left .application-cat-selection .input-label {
  display: none;
}
.app-overview-container .app-overview-top .applications-overview-left .application-cat-selection .input-box {
  font-size: 16px;
  font-family: 'Roboto-Medium';
  border: none;
}
.app-overview-container .app-overview-top .applications-overview-left .application-cat-selection .input-box .enerva-dropdown__control {
  background: no-repeat;
  border: none;
}
.app-overview-container .app-overview-top .applications-overview-left .application-cat-selection .input-box .enerva-dropdown__control .enerva-dropdown__single-value {
  color: #98989A;
}
.app-overview-container .app-overview-top .applications-overview-left .application-cat-selection .input-box .enerva-dropdown__control .enerva-dropdown__indicators .enerva-dropdown__indicator svg {
  color: #98989A;
}
.app-overview-container .app-overview-top .applications-overview-left .application-cat-selection .input-box:hover,
.app-overview-container .app-overview-top .applications-overview-left .application-cat-selection .input-box:focus {
  border: none;
  outline: none;
  text-decoration: none;
  box-shadow: none;
}
.app-overview-container .app-overview-bottom {
  display: flex;
  flex-direction: column;
  margin: 2em;
}
.app-overview-container .app-overview-bottom p {
  font-family: 'Roboto-Light';
  font-size: 16px;
  padding: 0 1em;
}
.app-overview-container .app-overview-bottom p span {
  color: #048041;
  font-weight: bold;
  cursor: pointer;
}
@media (max-width: 950px) {
  .app-overview-container .app-overview-bottom {
    flex-direction: column;
    margin: 0.5em;
  }
}
.app-overview-container .app-overview-bottom .separator {
  height: 120px;
  margin: 0 60px;
}
@media (max-width: 950px) {
  .app-overview-container .app-overview-bottom .separator {
    display: none;
  }
}
.app-overview-container .app-overview-bottom .app-overview-bottom-top-bar {
  display: flex;
  flex-direction: row;
  margin: 0 0 1em 0;
}
@media (max-width: 950px) {
  .app-overview-container .app-overview-bottom .app-overview-bottom-top-bar {
    flex-direction: column;
  }
}
.app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-search {
  display: flex;
  flex: 40% 0;
  margin-right: 10px;
}
@media (max-width: 950px) {
  .app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-search {
    margin-right: 0;
  }
}
.app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-filter {
  height: 45px;
}
.app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-appState {
  display: grid;
  grid-template-columns: 20% 80%;
  align-items: center;
  width: 350px;
}
@media (max-width: 950px) {
  .app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-appState {
    padding-top: 20px;
  }
}
@media (max-width: 600px) {
  .app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-appState {
    width: 100%;
  }
}
.app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-appState .appstate-label {
  font-size: 18px;
  margin-left: 8px;
  margin-top: 3px;
  color: #048041;
}
@media (max-width: 950px) {
  .app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-appState .appstate-label {
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-appState .appstate-label {
    margin-left: 50px;
    margin-top: unset;
  }
}
.app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-appState .appstate-input .input-parent .input-container .switch-container .label-container {
  display: none;
}
.app-overview-container .app-overview-bottom .app-overview-bottom-top-bar .app-overview-bottom-appState .appstate-input .input-parent .input-container .switch-container .switch-box .switch-value {
  display: none;
}
.app-overview-container .app-overview-bottom .application-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 3em;
}
@media (max-width: 950px) {
  .app-overview-container .app-overview-bottom .application-card-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
  }
}
.app-overview-container .app-overview-bottom .application-card-container .application-card {
  width: auto;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 2em;
}
@media (max-width: 950px) {
  .app-overview-container .app-overview-bottom .application-card-container .application-card {
    margin: 1em 0;
    width: auto;
  }
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .payout-icon {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .payout-icon .intitiate-payment-icon {
  width: 25px;
  height: 25px;
  padding-top: 5px;
  padding-left: 2px;
  margin-left: 10px;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .payout-icon .card-header {
  margin-right: 36px;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .card-icon-set {
  position: absolute;
  width: 58px;
  left: 15px;
  top: 91%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1em;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .card-icon-set .view-details-icon {
  display: flex;
  align-items: center;
  color: #048041;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .card-icon-set .view-details-icon:hover {
  opacity: 0.7;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .card-icon-set .view-details-icon {
  width: 25px;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .card-icon-set .change-request-icon {
  text-align: left;
  width: 18px;
  margin-top: -2px;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .card-icon-set .change-request-icon:hover {
  opacity: 0.5;
  cursor: pointer;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row {
  margin: 0.5em 0;
  display: flex;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row label {
  width: 40%;
  display: inline-block;
  text-align: right;
  padding: 0 10px;
  font-weight: bold;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row span {
  display: inline-block;
  width: 50%;
  color: #048041;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row.application-link {
  text-align: right;
  position: absolute;
  right: 15px;
  bottom: 0;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row.application-link span {
  display: block;
  width: auto;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row.application-link:hover {
  opacity: 0.7;
  cursor: pointer;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row.view-payment-link {
  text-align: right;
  position: absolute;
  right: 15px;
  bottom: 0;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row.view-payment-link span {
  display: block;
  width: auto;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row.view-payment-link:hover {
  opacity: 0.7;
  cursor: pointer;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card .application-card-row.card-header {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  padding: 1em 0;
  width: 100%;
  position: relative;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.completed-card {
  background: #04804140;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.completed-card .view-details-icon {
  color: #048041;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.completed-card .application-card-row.application-link span a {
  color: #048041;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.draft-under-review,
.app-overview-container .app-overview-bottom .application-card-container .application-card.draft-submitted-card {
  background-color: rgba(190, 228, 227, 0.25);
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.qaqc-card,
.app-overview-container .app-overview-bottom .application-card-container .application-card.application-info-card {
  background-color: rgba(255, 181, 0, 0.25);
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.app-rejected-card {
  background-color: rgba(186, 19, 26, 0.25);
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.app-draft-approved {
  background-color: rgba(0, 150, 57, 0.25);
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.app-submitted {
  background-color: rgba(0, 150, 57, 0.25);
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.draft-card {
  background: rgba(249, 221, 200, 0.75);
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.draft-card .view-details-icon {
  color: #eb7722;
}
.app-overview-container .app-overview-bottom .application-card-container .application-card.draft-card .application-card-row.application-link span a {
  color: #eb7722;
}
.app-overview-container .app-overview-bottom .pagination-container {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #048041;
  margin: 2em 0;
}
.app-overview-container .start-application-dropdown {
  width: 310px;
  text-transform: uppercase;
}
.app-overview-container .start-application-dropdown .input-parent .input-container .enerva-dropdown {
  border: none;
}
.app-overview-container .form-inner-section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 950px) {
  .app-overview-container .form-inner-section {
    flex-direction: column;
  }
}
.app-overview-container .form-inner-section .form-label {
  width: 50%;
  padding-right: 25px;
  text-align: right;
  font-family: 'Roboto-Regular';
  font-size: 18px;
}
@media (max-width: 950px) {
  .app-overview-container .form-inner-section .form-label {
    width: 100%;
    text-align: center;
    font-size: 12px;
  }
}
.app-overview-container .form-inner-section .form-element {
  width: 30%;
  padding-right: 25px;
  text-align: left;
  font-family: 'Roboto-Regular';
  font-size: 18px;
}
@media (max-width: 950px) {
  .app-overview-container .form-inner-section .form-element {
    width: 70%;
    text-align: center;
    margin-top: 15px;
  }
}
.postproject-start-form-sections {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.start-application-dropdown .input-container {
  margin-top: 0 !important;
}
@media (max-width: 950px) {
  .start-application-dropdown .input-container {
    margin-top: 15 !important;
  }
}
.start-application-dropdown .input-parent .input-container {
  display: block;
  position: relative;
  width: 100%;
}
.start-application-dropdown .input-parent .input-container .enerva-dropdown {
  float: right;
}
.back-button {
  appearance: none;
  background: transparent;
  border: none;
  display: block;
  position: absolute;
  top: 44px;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 896px) {
  .back-button {
    position: absolute;
    top: 0.8%;
    left: 0;
    padding-top: 1.2em;
  }
}
.back-button img {
  padding-right: 5px;
  width: 6px;
}
.preapproval-details-container {
  color: #001737;
  border: 1px solid #f2f2f2;
}
.preapproval-details-container .application-details-table {
  display: flex;
  flex-direction: row;
  max-height: 280px;
}
.preapproval-details-container .application-details-table .application-details-table-left {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-header {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Medium';
  text-align: left;
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-header .application-detail {
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-header .application-detail:nth-child(1) {
  width: 50%;
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-header .application-detail:nth-child(2) {
  width: 50%;
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-inner-container {
  max-height: 500px;
  width: 100%;
  overflow: auto;
}
@media (max-width: 896px) {
  .preapproval-details-container .application-details-table .application-details-table-left .application-details-inner-container {
    height: 200px;
  }
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-inner-container .application-details {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-inner-container .application-details .application-detail {
  display: flex;
  align-items: center;
  padding: 1em;
  text-align: left;
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-inner-container .application-details .application-detail p {
  font-size: 14px;
  text-align: left;
  color: #535765;
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-inner-container .application-details .application-detail:nth-child(1) {
  width: 50%;
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-inner-container .application-details .application-detail:nth-child(2) {
  width: 50% ;
}
.preapproval-details-container .application-details-table .application-details-table-left .application-details-inner-container .application-details .application-status span {
  margin-right: 1em;
}
.preapproval-details-container .application-details-table .application-details-table-left .app-detail-totals-inner-container .app-detail-totals {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}
.preapproval-details-container .application-details-table .application-details-table-left .app-detail-totals-inner-container .app-detail-totals .detail {
  display: flex;
  align-items: center;
  padding: 1em;
  text-align: left;
}
.preapproval-details-container .application-details-table .application-details-table-left .app-detail-totals-inner-container .app-detail-totals .detail:nth-child(1) {
  width: 20%;
}
.preapproval-details-container .application-details-table .application-details-table-left .app-detail-totals-inner-container .app-detail-totals .detail:nth-child(2) {
  width: 20% ;
}
.preapproval-details-container .application-details-table .application-details-table-left .app-detail-totals-inner-container .app-detail-totals .detail:nth-child(3) {
  width: 20% ;
}
.preapproval-details-container .application-details-table .application-details-table-left .app-detail-totals-inner-container .app-detail-totals .detail:nth-child(4) {
  width: 20% ;
}
.preapproval-details-container .application-details-table .application-details-table-left .app-detail-totals-inner-container .app-detail-totals .detail:nth-child(5) {
  width: 20% ;
}
.preapproval-details-container .application-details-table .application-details-table-right {
  display: flex;
  flex: 0 1 35%;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #001737;
  flex-direction: column;
  justify-content: center;
}
.preapproval-details-container .application-details-table .application-details-table-right p {
  text-align: left;
  color: #001737;
}
.preapproval-details-container .application-details-table .application-details:nth-child(even) {
  background: #F8F7F6;
}
@media (max-width: 1024px) {
  .preapproval-details-container .application-details-table {
    padding: 1em;
    flex-direction: column;
  }
  .preapproval-details-container .application-details-table .vertical-separator-box {
    display: none;
  }
}
