.button-selection-list {
  display: flex;
  flex-direction: row;
}
.button-selection-list.disable-btn-selction {
  pointer-events: none;
}
.button-selection-list .btn-selection {
  margin: 4px 8px;
  background-color: #EFEFEF;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  min-width: 70px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-selection-list .btn-selection:hover {
  background: #048041;
  color: #FFF;
}
.button-selection-list .btn-selection label {
  min-width: 70px;
  height: 35px;
  user-select: none;
}
.button-selection-list .btn-selection label span {
  text-align: center;
  min-width: 70px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.button-selection-list .btn-selection label:hover {
  opacity: 0.7;
  cursor: pointer;
}
.button-selection-list .btn-selection label input {
  position: absolute;
  top: -20px;
}
.button-selection-list .btn-selection label input:checked + span {
  background-color: #048041;
  color: #fff;
}
