.floating-app-button-container .MuiAppBar-colorDefault {
  max-width: 480px;
}
.floating-app-button-container .MuiButtonBase-root {
  background-color: #048041;
  border-radius: 50%;
  position: fixed;
  right: 5%;
  bottom: 3%;
  z-index: 4;
}
.floating-app-button-container .MuiButtonBase-root:hover {
  background-color: #048041;
}
.floating-app-button-container .MuiButtonBase-root .MuiFab-label .MuiSvgIcon-root {
  width: 1.6em;
  height: 1.6em;
}
.floating-app-button-container .MuiTab-textColorPrimary {
  color: #535765;
  font-family: 'Roboto-Regular';
  font-size: 16px;
}
.floating-app-button-container .MuiPaper-elevation4 {
  box-shadow: unset;
}
.floating-app-button-container .MuiTab-root {
  text-transform: unset;
  min-width: 120px;
}
.floating-app-button-container .checkbox-arrow {
  color: #048041;
}
.floating-app-button-container .MuiIconButton-label {
  color: #048041;
}
.floating-app-button-container .error-box {
  border-left: 4px solid #DB1900;
  outline: none;
  text-decoration: none;
}
.floating-app-button-container .error-box .checkbox-arrow {
  color: #DB1900;
}
.floating-app-button-container .error-box .MuiIconButton-label {
  color: #DB1900;
}
.floating-app-button-container .error-box .MuiTypography-root p {
  color: #DB1900;
}
.floating-app-button-container .error-box .MuiTypography-root p a {
  color: #DB1900;
}
.floating-app-button-container .success-box {
  border-left: 4px solid #048041;
}
.floating-app-button-container .MuiTypography-root div {
  font-size: 14px;
}
.floating-app-button-container .MuiCheckbox-colorSecondary .Mui-checked {
  background-color: #048041;
}
.floating-app-button-container .MuiAccordion-root.Mui-expanded {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
