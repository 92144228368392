.burger-menu {
  height: 40%;
  position: absolute;
  top: 22.5px;
  right: 10px;
  z-index: 20;
  display: none;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .burger-menu {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
}
.burger-menu .burger-icon {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  transform-origin: 3px;
  transition: all 0.3s linear;
}
.burger-menu .close-menu {
  background-color: #048041;
}
.burger-menu .close-menu:nth-child(1) {
  transform: rotate(0);
}
.burger-menu .close-menu:nth-child(2) {
  transform: translateX(0);
  opacity: 1;
}
.burger-menu .close-menu:nth-child(3) {
  transform: rotate(0);
}
.burger-menu .open-menu {
  background-color: #fff;
}
.burger-menu .open-menu:nth-child(1) {
  transform: rotate(45deg);
}
.burger-menu .open-menu:nth-child(2) {
  transform: translateX(100%);
  opacity: 0;
  display: none;
}
.burger-menu .open-menu:nth-child(3) {
  transform: rotate(-45deg);
}
.enerva-dropdown__menu {
  z-index: 999;
}
.nav-ul {
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
@media (min-width: 1001px) {
  .nav-ul {
    width: 100%;
    padding: 0;
    justify-content: flex-end;
  }
}
.nav-ul li {
  padding: 18px 10px;
}
.nav-ul .menu-title a,
.nav-ul .menu-title p,
.nav-ul .menu-title span {
  text-transform: uppercase;
  color: #535765;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
}
@media (max-width: 1000px) {
  .nav-ul .menu-title a,
  .nav-ul .menu-title p,
  .nav-ul .menu-title span {
    display: none;
  }
}
.nav-ul .menu-title:hover {
  opacity: 0.7;
}
.nav-ul .menuItem {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  margin: 0 1em;
}
@media (max-width: 1000px) {
  .nav-ul .menuItem {
    width: 95%;
    font-size: 12px;
  }
  .nav-ul .menuItem .start-application-menu-item .input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__placeholder {
    font-size: 12px;
  }
}
.nav-ul .menuItem .input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__value-container {
  padding-left: 10px !important;
  cursor: pointer;
}
.nav-ul .menuItem .user-menu-container {
  display: none;
}
@media (max-width: 1000px) {
  .nav-ul .menuItem .user-menu-container {
    display: flex;
    flex-direction: column;
    padding: 1em;
    text-transform: uppercase;
  }
}
.nav-ul .menuItem .user-menu-container .user-menu-items {
  cursor: pointer;
  padding: 1em;
}
.nav-ul .menuItem .user-menu-container .user-menu-items:nth-child(1):hover {
  opacity: 1;
}
.nav-ul .menuItem .user-menu-container .user-menu-items:hover {
  opacity: 0.7;
}
.nav-ul .menuItem .user-menu-container .user-menu-items.user-title {
  text-transform: initial;
}
.nav-ul .menuItem .user-menu-container .user-menu-items a {
  padding: 0;
  color: #fff;
}
.nav-ul .menuItem .user-menu-container .start-application-menu-item {
  padding: 0;
}
.nav-ul .menuItem .user-menu-container .start-application-menu-item .enerva-dropdown__placeholder {
  font-size: 16px;
}
.nav-ul .menuItem .user-menu-container .start-application-menu-item .enerva-dropdown__placeholder:hover {
  opacity: 0.7;
}
.nav-ul .menuItem .user-menu-container .start-application-menu-item:hover {
  opacity: 1 !important;
}
.nav-ul .menuItem.new-application-dropdown:hover {
  opacity: 1;
}
.nav-ul .menuItem.new-application-dropdown .start-application-dropdown .input-parent .input-container .input-box {
  cursor: pointer;
  border-radius: 0px;
  background-color: #048041;
}
.nav-ul .menuItem.new-application-link {
  background-color: #048041;
  padding: 10px 20px;
}
.nav-ul .menuItem.new-application-link p {
  color: #FFFFFF;
}
.nav-ul .menuItem.new-application-link:hover {
  opacity: 1;
}
.nav-ul .menuItem.start-project {
  width: 220px;
  text-transform: uppercase;
}
.nav-ul .menuItem.start-project .input-parent .input-container .enerva-dropdown {
  width: 100% !important;
}
@media (max-width: 1000px) {
  .nav-ul {
    flex-flow: column nowrap;
    background-color: #048041;
    position: fixed;
    transform: translateX(100%);
    top: 0;
    right: 0;
    height: 100%;
    width: 220px;
    padding-top: 4.5rem;
    transition: transform 0.3s ease-in-out;
    margin: 0;
    z-index: 1;
  }
  .nav-ul li {
    color: #fff;
  }
  .nav-ul.menu-expand {
    padding-left: 0px;
    transform: translateX(0);
  }
}
.account-name-menu-item {
  position: relative;
  width: 160px;
  height: 7px;
}
.account-name-menu-item .account-name-container {
  user-select: none;
  top: 0px;
  right: 0px;
  font-size: 16px;
  text-transform: uppercase;
  padding: 0;
  position: absolute;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  right: 0;
  border-left: 1px solid #98989a40;
  background: #fff;
  border-right: 1px solid #98989a40;
  border-top: 1px solid #98989a40;
  width: 160px;
  z-index: 4;
}
.account-name-menu-item .account-name-container.user-menu-expanded {
  box-shadow: 0px 2px 5px #ccccb8;
}
.account-name-menu-item .account-name-container .user-menu-items {
  display: flex;
  flex-direction: column;
  padding: 5px 15px;
  border-bottom: 1px solid #98989a40;
  font-size: 12px;
}
.account-name-menu-item .account-name-container .user-menu-items.logout-icon {
  font-size: 16px;
  padding: 10px 15px;
  background-image: url("../../../images/LogOut.svg");
  background-repeat: no-repeat;
  background-position: 90% 50%;
  min-height: 20px;
  padding-right: 50px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 360px) {
  .account-name-menu-item .account-name-container .user-menu-items.logout-icon {
    padding-right: 25px;
  }
}
.account-name-menu-item .account-name-container .user-menu-items.hide-menu-items {
  display: none;
}
.account-name-menu-item .account-name-container .user-menu-items a {
  color: #535765;
  padding: 0;
}
@media (max-width: 1000px) {
  .account-name-menu-item .account-name-container {
    display: none;
  }
}
.register-button-container {
  font-size: 16px;
  background-color: #FFFFFF;
  text-transform: uppercase;
  border: 1px solid #98989a40;
  padding: 10px 15px;
  display: inline-table;
  font-family: "Roboto-Medium";
  display: flex;
  height: 18px;
  position: relative;
  color: #535765;
  max-width: 185px;
  cursor: pointer;
}
.register-button-container img {
  width: 100%;
  max-width: 23px;
  height: 100%;
  max-height: 23px;
}
.register-button-container .login-register-link {
  color: #048041;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  background-size: 18px 20px;
  background-repeat: no-repeat;
  width: 18px;
  height: 20px;
  margin: 0 0 0 5px;
}
.register-button-container .login-register-link.login-icon {
  background-image: url("../../../images/Login.svg");
}
.register-button-container .login-register-link.logout-icon {
  background-image: url("../../../images/Login.svg");
}
