.checkbox-list-container .checkbox-arrow {
  color: #048041;
}
.checkbox-list-container .MuiIconButton-label {
  color: #048041;
}
.checkbox-list-container .error-box {
  border-left: 4px solid #DB1900;
  outline: none;
  text-decoration: none;
}
.checkbox-list-container .error-box .checkbox-arrow {
  color: #DB1900;
}
.checkbox-list-container .error-box .MuiIconButton-label {
  color: #DB1900;
}
.checkbox-list-container .error-box .MuiTypography-root p {
  color: #DB1900;
}
.checkbox-list-container .error-box .MuiTypography-root p a {
  color: #DB1900;
}
.checkbox-list-container .success-box {
  border-left: 4px solid #048041;
}
.checkbox-list-container .accordion-summary {
  padding-left: 20px;
  padding-right: 20px;
}
.checkbox-list-container .accordion-summary .MuiTypography-root p span {
  font-family: 'Roboto-Medium';
}
.checkbox-list-container .term-checkbox {
  padding-right: 15px;
}
.checkbox-list-container .accordion-summary {
  background-color: #F4F4F4;
  width: 100%;
}
.checkbox-list-container .MuiTypography-root div {
  font-size: 14px;
}
.checkbox-list-container .MuiCheckbox-colorSecondary .Mui-checked {
  background-color: #048041;
}
.checkbox-list-container .MuiAccordion-root.Mui-expanded {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
