.mat-tabs-container .MuiAppBar-colorDefault {
  max-width: 480px;
}
.mat-tabs-container .MuiTab-textColorPrimary {
  color: #535765;
  font-family: 'Roboto-Regular';
  font-size: 16px;
}
.mat-tabs-container .MuiPaper-elevation4 {
  box-shadow: unset;
}
.mat-tabs-container .MuiTab-root {
  text-transform: unset;
  min-width: 120px;
}
.mat-tabs-container .checkbox-arrow {
  color: #048041;
}
.mat-tabs-container .MuiIconButton-label {
  color: #048041;
}
.mat-tabs-container .error-box {
  border-left: 4px solid #DB1900;
  outline: none;
  text-decoration: none;
}
.mat-tabs-container .error-box .checkbox-arrow {
  color: #DB1900;
}
.mat-tabs-container .error-box .MuiIconButton-label {
  color: #DB1900;
}
.mat-tabs-container .error-box .MuiTypography-root p {
  color: #DB1900;
}
.mat-tabs-container .error-box .MuiTypography-root p a {
  color: #DB1900;
}
.mat-tabs-container .success-box {
  border-left: 4px solid #048041;
}
.mat-tabs-container .MuiTypography-root div {
  font-size: 14px;
}
.mat-tabs-container .MuiCheckbox-colorSecondary .Mui-checked {
  background-color: #048041;
}
.mat-tabs-container .MuiAccordion-root.Mui-expanded {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
