@keyframes blink {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
@keyframes lightToDarkTextAnim {
  0% {
    color: rgba(83, 87, 101, 0.4);
  }
  25% {
    color: rgba(83, 87, 101, 0.7);
  }
  50% {
    color: #535765;
  }
  100% {
    color: #535765;
  }
}
@keyframes colorchanging {
  0% {
    border-left: 20px solid rgba(4, 128, 65, 0.25);
  }
  50% {
    opacity: 10px solid rgba(4, 128, 65, 0.5);
  }
  100% {
    opacity: 3px solid #048041;
  }
}
.email-verification-pin .invalid-message {
  color: #DB1900;
}
.email-verification-pin .resend-email {
  display: flex;
  flex-direction: row;
  justify-content: center;
  color: #048041;
  margin-bottom: 10px;
}
.email-verification-pin .resend-email .resend-btn {
  font-family: 'Roboto-Medium';
  margin-left: 5px;
  cursor: pointer;
}
.email-verification-pin .pins-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
}
.email-verification-pin .pins-container .pin-code {
  border-radius: 5px;
  font-size: 55px;
  height: 60px;
  width: 55px;
  border: 1px solid #E3DDDA;
  outline-width: thin;
  outline-color: #ddd;
  margin: 1%;
  text-align: center;
  font-weight: 300;
  -moz-appearance: textfield;
  margin-left: 10px;
  color: #048041;
}
.email-verification-pin .pins-container .pin-code::-webkit-outer-spin-button,
.email-verification-pin .pins-container .pin-code::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.email-verification-pin .pins-container .pin-code:valid {
  border-color: #048041;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
@media (max-width: 600px) {
  .email-verification-pin .pins-container {
    flex-wrap: wrap;
  }
  .email-verification-pin .pins-container .pin-code {
    font-size: 20px;
    height: 20px;
    width: 20px;
  }
  .email-verification-pin .resend-email {
    font-size: 10px;
  }
}
.password-inputs .input-parent .success-message {
  padding-left: 41%;
}
.update-naics p {
  text-align: center;
  margin-bottom: 40px;
}
.update-naics .container {
  min-height: 300px;
  width: 94%;
  box-shadow: 0px 0px 0px #5a71d01b;
  border: 0.5px solid #98989a;
  padding: 1.5em;
  text-align: center;
  text-align: left;
  overflow: overlay;
}
@media (max-width: 896px) {
  .update-naics .container {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 5px;
  }
}
.update-naics .container .input-parent {
  margin-bottom: 40px;
}
.update-naics .container .input-parent .error-message {
  padding-left: 25%;
}
.update-naics .container .input-parent .input-container .input-label {
  flex: 25% 1;
}
.question-update p {
  text-align: center;
  margin-bottom: 40px;
}
.question-update .container {
  min-height: 300px;
  width: 94%;
  box-shadow: 0px 0px 0px #5a71d01b;
  border: 0.5px solid #98989a;
  padding: 1.5em;
  text-align: center;
  text-align: left;
  overflow: hidden;
}
@media (max-width: 896px) {
  .question-update .container {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 5px;
  }
}
.question-update .container .input-parent {
  margin-bottom: 140px;
}
@media (max-width: 600px) {
  .question-update .container .input-parent {
    margin-bottom: 100px;
  }
}
.question-update .container .input-parent .error-message {
  padding-left: 25%;
}
@media (max-width: 600px) {
  .question-update .container .input-parent .input-container .enerva-dropdown__placeholder {
    font-size: 12px;
  }
  .question-update .container .input-parent .input-container .enerva-dropdown__menu {
    font-size: 12px;
  }
}
.question-update .container .input-parent .input-container .input-label {
  flex: 180% 1;
  padding: unset;
  padding-right: 10px;
}
@media (max-width: 600px) {
  .question-update .container .input-parent .input-container .input-label {
    width: 100%;
    font-size: 12px;
    padding-bottom: 10px;
  }
}
.payment-pin-modal {
  display: flex;
  flex-direction: column;
}
.payment-pin-modal .container-for-banking {
  border: 0.5px solid #E3DDDA;
  width: 96%;
  height: 430px;
  padding: 20px 20px;
  overflow-y: auto;
}
@media (max-width: 415px) {
  .payment-pin-modal .container-for-banking {
    width: unset;
    overflow: hidden;
    max-height: 290px;
  }
}
@media (min-width: 415px) {
  .payment-pin-modal .container-for-banking .payment-pin-confirmation .checkboxes-section {
    max-height: unset;
    overflow: hidden;
  }
}
.payment-pin-modal .close-viewpin {
  max-width: 300px !important;
  flex: unset;
  width: 100%;
}
.payment-pin-modal p {
  font-size: 16px !important;
}
.payment-pin-modal .pin-blocks {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
}
.payment-pin-modal .pin-blocks .block {
  width: 70px;
  height: 70px;
  border: 0.2px solid rgba(83, 87, 101, 0.3);
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #048041;
  display: flex;
  margin: 0 15px;
}
@media (max-width: 768px) {
  .payment-pin-modal .pin-blocks .block {
    margin: 0 5px;
    width: 40px;
    height: 40px;
  }
}
.payment-pin-modal .payment-pin-confirmation {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
  font-size: 16px !important;
}
@media (max-width: 415px) {
  .payment-pin-modal .payment-pin-confirmation {
    max-height: 415px;
    overflow: auto;
  }
}
.payment-pin-modal .payment-pin-confirmation .green-text {
  color: #048041;
  font-family: 'Roboto-Medium';
}
.payment-pin-modal .payment-pin-confirmation .form-inner-section .bold {
  font-family: 'Roboto-Bold';
}
.payment-pin-modal .payment-pin-confirmation p {
  text-align: left !important;
}
@media (min-width: 415px) {
  .payment-pin-modal .payment-pin-confirmation .checkboxes-section {
    margin: 20px 0;
    max-height: 200px;
    overflow: scroll;
    overflow-x: hidden;
  }
}
.payment-pin-modal .request-button .enerva-btn {
  padding: 8px 28px;
}
.payment-pin-modal .request-button .enerva-btn .request-button-title {
  display: flex;
  flex-direction: column;
}
.payment-pin-modal .request-button .enerva-btn .request-button-title div {
  font-size: 16px;
}
.payment-pin-modal .request-button .enerva-btn .request-button-title span {
  font-size: 8px;
  color: #fff;
}
@media (max-width: 896px) {
  .payment-pin-modal .request-button .enerva-btn {
    width: 100%;
    margin-left: 0%;
    margin-right: 0%;
  }
}
.account-container {
  max-width: 1030px;
  width: 85vw;
  margin: auto;
  min-height: 78vh;
}
@media (max-width: 896px) {
  .account-container {
    width: 95%;
  }
}
.account-container .welcome-message {
  font-size: 24px;
  font-family: 'Roboto-Medium';
  color: #535765;
  text-align: center;
  font-weight: normal;
}
.account-container .welcome-message span {
  color: #048041;
}
.account-container .flashing-message {
  animation: lightToDarkTextAnim 2s linear infinite;
}
.account-container .subtitle-message {
  font-weight: light;
  text-align: left;
  font-family: 'Roboto-Light';
  font-size: 20px;
  padding-left: 1em;
  border-left: 3px solid #048041;
}
.account-container .subtitle-message span {
  font-family: 'Roboto-Regular';
  font-weight: bold;
}
@media (max-width: 768px) {
  .ReactModalPortal .share-bank-details-heading {
    font-size: 1.2em;
  }
}
.revise-details-container {
  border: 0.5px solid #E3DDDA;
  width: 96%;
  padding: 20px 20px;
  min-height: 260px;
}
@media (max-width: 415px) {
  .revise-details-container {
    width: unset;
    min-height: unset;
  }
}
.revise-details-container .reason-heading {
  font-family: 'Roboto-Medium' !important;
}
.revise-details-container .form-inner-section .form-element .input-parent .disable-input-container {
  height: 110px;
}
.revise-details-container .form-inner-section .form-element .input-parent .disable-input-container .ql-toolbar {
  display: none;
}
.revise-details-container .form-inner-section .form-element .input-parent .disable-input-container .ql-container {
  border: unset;
}
.revise-details-container .form-inner-section .form-element .input-parent .disable-input-container .ql-container .ql-editor {
  min-height: 100px;
}
.request-eft-payment-small {
  border: 0.5px solid #E3DDDA;
  width: 94%;
  height: 330px;
  padding: 20px 20px;
  margin-bottom: 10px;
  overflow-y: auto;
}
@media (max-width: 415px) {
  .request-eft-payment-small {
    width: unset;
    max-height: 270px;
  }
}
@media (max-width: 786px) {
  .request-eft-payment-small p {
    text-align: left;
  }
}
.request-eft-payment-small .bank-details {
  display: flex;
  flex-direction: column;
  padding-top: 2em;
}
.request-eft-payment-small .bank-details .detail {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-family: 'Roboto-Medium';
  margin-bottom: 15px;
  align-items: center;
}
@media (max-width: 786px) {
  .request-eft-payment-small .bank-details .detail {
    flex-direction: column;
    font-size: 18px;
  }
}
.request-eft-payment-small .bank-details .detail label {
  width: 50%;
  text-align: right;
  margin-right: 2%;
  color: #048041;
}
@media (max-width: 768px) {
  .request-eft-payment-small .bank-details .detail label {
    width: 100%;
    margin-right: unset;
    text-align: left;
  }
}
.request-eft-payment-small .bank-details .detail span {
  width: 50%;
  text-align: left;
  margin-left: 2%;
  color: #535765;
  font-family: 'Roboto-Regular';
}
@media (max-width: 768px) {
  .request-eft-payment-small .bank-details .detail span {
    width: 100%;
    margin-right: unset;
    text-align: left;
  }
}
.request-eft-payment-small .bank-details .detail span .void-cheque-detail {
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  background: #e3ddda;
  padding-bottom: 0px;
}
.request-eft-payment-small .bank-details .detail span .void-cheque-detail .document-name {
  width: 80%;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.request-eft-payment-small .bank-details .detail span .void-cheque-detail .document-view {
  cursor: pointer;
}
.request-eft-payment-small .bank-details .detail span .void-cheque-detail .document-view:hover {
  opacity: 0.7;
}
.request-eft-payment-large {
  border: 0.5px solid #E3DDDA;
  width: 96%;
  height: 430px;
  padding: 20px 20px;
  margin-bottom: 10px;
  overflow-y: auto;
}
@media (max-width: 415px) {
  .request-eft-payment-large {
    width: unset;
    max-height: 270px;
  }
}
.request-eft-payment-large .bank-details {
  display: flex;
  flex-direction: column;
}
.request-eft-payment-large .bank-details .detail {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-family: 'Roboto-Medium';
  margin-bottom: 15px;
  align-items: center;
}
@media (max-width: 786px) {
  .request-eft-payment-large .bank-details .detail {
    flex-direction: column;
    font-size: 18px;
  }
}
.request-eft-payment-large .bank-details .detail label {
  width: 50%;
  text-align: right;
  margin-right: 2%;
  color: #048041;
}
@media (max-width: 768px) {
  .request-eft-payment-large .bank-details .detail label {
    width: 100%;
    margin-right: unset;
    text-align: left;
  }
}
.request-eft-payment-large .bank-details .detail span {
  width: 50%;
  text-align: left;
  margin-left: 2%;
  color: #535765;
}
@media (max-width: 768px) {
  .request-eft-payment-large .bank-details .detail span {
    width: 100%;
    margin-right: unset;
    text-align: left;
    font-family: 'Roboto-Regular';
  }
}
.request-eft-payment-large .bank-details .detail span .void-cheque-detail {
  display: flex;
  padding: 0.5em;
  flex-direction: row;
  background: #e3ddda;
  flex-basis: 90% 10%;
}
.request-eft-payment-large .bank-details .detail span .void-cheque-detail .document-name {
  width: 80%;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.request-eft-payment-large .bank-details .detail span .void-cheque-detail .document-view {
  cursor: pointer;
}
.request-eft-payment-large .bank-details .detail span .void-cheque-detail .document-view:hover {
  opacity: 0.7;
}
.request-eft-payment-large p {
  font-family: 'Roboto-Regular';
  text-align: left;
}
.request-eft-payment-large .form-inner-section .form-inner-elements {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: 768px) {
  .request-eft-payment-large .form-inner-section .form-inner-elements {
    display: flex;
    flex-direction: column;
  }
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-label {
  width: 25%;
  font-size: 18px;
  font-family: 'Roboto-Medium';
  color: #048041;
  text-align: right;
}
@media (max-width: 768px) {
  .request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-label {
    width: 100%;
    text-align: left;
  }
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element {
  width: 35%;
  padding-bottom: 10px;
  text-align: start;
}
@media (max-width: 768px) {
  .request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element {
    width: 100%;
  }
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .input-box {
  color: #535765;
  font-size: 14px;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container {
  width: 90%;
  padding: 0.5em 1em;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone {
  flex-direction: row;
  padding: 0.5em 1em;
  color: darkgray;
  font-family: 'Roboto-Light';
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone .message-container {
  font-size: 'Roboto-Regular';
  text-align: left;
  font-size: 14px;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone .message-container .drawer-error-message {
  color: #DB1900;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone .document-name {
  overflow: hidden;
  margin-right: 10px;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone .document-view svg {
  width: unset;
  height: unset;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone .document-delete svg {
  width: unset;
  height: unset;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone svg {
  margin-right: 5px;
}
@media (max-width: 786px) {
  .request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone svg {
    height: 40px;
    width: auto;
  }
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone svg:hover {
  cursor: pointer;
  opacity: 0.7;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone svg path {
  fill: #535765;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .file-upload-container .file-dropzone .file-upload-prompt {
  font-size: 14px !important;
  text-align: start;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .input-parent .input-container .disable-input {
  color: #535765;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-element .error-message {
  padding-left: 4px;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty {
  width: 35%;
  padding-bottom: 10px;
  text-align: start;
}
@media (max-width: 786px) {
  .request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty {
    width: 100%;
  }
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .error-message {
  display: none;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .input-box {
  color: #535765;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container {
  width: 90%;
  padding: 0.5em 1em;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone {
  flex-direction: row;
  padding: 0.5em 1em;
  color: darkgray;
  font-family: 'Roboto-Light';
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone .message-container {
  font-size: 'Roboto-Regular';
  text-align: left;
  font-size: 14px;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone .message-container .drawer-error-message {
  color: #DB1900;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone .document-view svg {
  width: unset;
  height: unset;
  margin-right: 10px;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone .document-delete svg {
  width: unset;
  height: unset;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone svg {
  margin-right: 5px;
}
@media (max-width: 786px) {
  .request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone svg {
    height: 30px;
    width: auto;
  }
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone svg:hover {
  cursor: pointer;
  opacity: 0.7;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone svg path {
  fill: #DB1900;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .file-upload-container .file-dropzone .file-upload-prompt {
  font-size: 14px !important;
  text-align: start;
  color: #DB1900;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .input-parent .input-container .disable-input {
  color: #535765;
}
.request-eft-payment-large .form-inner-section .form-inner-elements .bank-details-form-empty .error-message {
  padding-left: 4px;
}
.request-eft-payment-large .form-inner-section .form-inner-elements span {
  color: #535765;
  font-size: 12px;
  font-family: 'Roboto-Regular';
}
.request-eft-payment-large .form-inner-section .checkbox-list-container p {
  text-align: left;
}
@media (max-width: 786px) {
  .request-eft-payment-large .form-inner-section .checkbox-list-container {
    margin-top: 10px;
  }
}
.change-request-app-selection-container .form-inner-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 15px 0;
}
.change-request-app-selection-container .form-inner-section .form-label {
  justify-content: center;
  align-items: center;
  font-size: 18px;
}
@media (max-width: 896px) {
  .change-request-app-selection-container .form-inner-section .form-label {
    font-size: 14px;
  }
}
.change-request-app-selection-container .form-inner-section .form-element {
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 0px;
  width: 40%;
}
.change-request-app-selection-container .form-inner-section .form-element .input-container {
  margin-top: 0px;
}
.change-request-app-selection-container .form-inner-section p {
  font-size: 16px;
  text-align: left;
  width: 85%;
}
.change-request-app-selection-container .form-inner-section p span {
  font-family: 'Roboto-Medium';
  color: #535765;
}
.view-application-container {
  width: 90%;
  max-width: 1024px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.view-application-container .agreement-text-area {
  width: 100%;
}
.view-application-container .agreement-text-area input {
  width: 100%;
  background-color: #DBDBDB;
  color: #535765 !important;
  border: none !important;
  border-radius: 2px;
  resize: none;
  font-family: 'Roboto-Medium';
}
.view-application-container .subtitle-text {
  padding-left: 2em;
}
.view-application-container .file-upload-prompt {
  text-align: center;
  color: #535765 !important;
  font-family: 'Roboto-Light';
  font-size: 16px !important;
}
.view-application-container .file-upload-prompt span {
  color: #535765;
  font-family: 'Roboto-Medium';
}
@media (max-width: 896px) {
  .view-application-container {
    width: 96%;
  }
}
@media (max-width: 500px) {
  .view-application-container .MuiStepLabel-labelContainer .step-label {
    display: none;
  }
}
.view-application-container .step-header-title {
  font-size: 24px;
  margin: 0;
  display: flex;
  text-align: left;
  margin: 20px 0;
  padding: 0 1em;
}
@media (max-width: 896px) {
  .view-application-container .step-header-title {
    align-items: center;
    padding: 0;
  }
}
.view-application-container .form-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.view-application-container .form-bottom .document-note-container {
  font-size: 16px;
  text-align: left;
  padding: 0;
  margin: 2em 0;
  line-height: 24px;
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: flex-end;
}
.view-application-container .form-bottom .document-note-container b {
  color: #048041;
}
.view-application-container .form-bottom .document-note-container .document-note-container-left {
  flex: 75% 0;
}
.view-application-container .form-bottom .document-note-container .document-note-container-right {
  flex: 0 35%;
  justify-content: flex-end;
  display: flex;
}
.view-application-container .form-bottom .save-btn {
  width: 100%;
  margin-top: 15px;
  max-width: 250px;
  text-align: center;
  height: 41px;
}
@media (max-width: 896px) {
  .view-application-container .form-bottom .save-btn {
    max-width: unset;
  }
}
.view-application-container .form-bottom .inversed-btn {
  width: 100%;
  margin-top: 15px;
  max-width: 250px;
  text-align: center;
}
@media (max-width: 896px) {
  .view-application-container .form-bottom .inversed-btn {
    max-width: unset;
  }
}
.view-application-container .back-button {
  appearance: none;
  background: transparent;
  border: none;
  display: block;
  position: absolute;
  left: 0px;
  top: 39px;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 896px) {
  .view-application-container .back-button {
    position: absolute;
    top: 0.8%;
    left: 0;
    padding-top: 1.2em;
  }
}
.view-application-container .back-button img {
  padding-right: 5px;
  width: 6px;
}
@media (max-width: 896px) {
  .view-application-container .step-count {
    position: absolute;
    top: 0.8%;
    right: 0;
  }
}
.view-application-container .section-header {
  padding-top: 2.5em;
  padding-bottom: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 896px) {
  .view-application-container .section-header {
    padding-top: 5em;
    padding-bottom: 1em;
  }
}
.view-application-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto-Medium";
  font-weight: normal;
  max-width: 600px;
  margin: auto;
}
.view-application-container .scrollable {
  overflow-y: auto;
  max-height: 300px;
}
.view-application-container .container-box {
  width: 100%;
  box-shadow: 0px 0px 0px #5a71d01b;
  border: 0.5px solid #98989a;
  padding: 1.5em;
  text-align: center;
  text-align: left;
  overflow: overlay;
}
@media (max-width: 896px) {
  .view-application-container .container-box {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 5px;
  }
}
.view-application-container .container-box .agree-to-terms {
  display: flex;
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: #048041;
  margin-top: 1em;
}
.view-application-container .container-box .subtitle {
  color: #048041;
  font-size: 24px;
  text-align: left;
  font-family: 'Roboto-Medium';
}
.view-application-container .container-box .title {
  color: #DBDBDB;
  font-weight: bold;
  text-align: left;
  font-family: 'Roboto-Bold';
  font-size: 18px;
}
.view-application-container .container-box p {
  font-size: 16px;
  text-align: left;
  font-family: 'Roboto-Regular';
}
.view-application-container .container-box .vertical-separator-box {
  display: flex;
  opacity: 0.5;
  flex: 8%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.view-application-container .container-box .vertical-separator-box .separator {
  background-color: #048041;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32%;
  height: 77%;
}
@media (max-width: 896px) {
  .view-application-container .container-box .vertical-separator-box {
    display: none;
  }
}
.view-application-container .container-box .form-sections {
  display: flex;
  flex-direction: column;
  margin: 0 0 3em 0;
}
.view-application-container .container-box .form-sections .step-header-container {
  display: flex;
  align-items: center;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .step-header-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.view-application-container .container-box .form-sections .step-header-container .step-header-container-right {
  display: flex;
  align-items: center;
}
.view-application-container .container-box .form-sections .step-header-container .step-header-container-left {
  display: flex;
  align-items: center;
}
.view-application-container .container-box .form-sections .step-header-container .enerva-btn {
  margin: 0;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .step-header-container .enerva-btn {
    margin: 10px 0;
  }
}
.view-application-container .container-box .form-sections .step-header-container .info-icon {
  margin: 0 15px;
  width: 20px;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .step-header-container .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.view-application-container .container-box .form-sections .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #e3ddda;
  margin: 3em 25%;
  width: 50%;
}
.view-application-container .container-box .form-sections .form-inner-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  align-items: center;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .form-inner-section {
    width: 100%;
  }
}
.view-application-container .container-box .form-sections .form-inner-section .step-header-title {
  width: 100%;
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements {
  display: flex;
  width: 100%;
  margin: 1em 0;
  align-items: center;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .form-inner-section .form-inner-elements {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-label {
  flex: 50% 0;
  text-align: left;
  font-size: 16px;
  padding: 0 2em;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-label {
    margin: 10px 0;
    padding: 0;
  }
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element {
  flex: 0 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .legal-name-info {
  position: unset !important;
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .info-icon {
  margin: 0 10px;
  width: 20px;
  position: absolute;
  right: 0;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-container {
  justify-content: flex-end;
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .error-message {
  padding: 5px 0;
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-box {
  width: 240px;
  height: 24px;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-box.enerva-dropdown {
  height: 44px;
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-box.textarea-box {
  width: 240px;
  height: 40px;
}
.view-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .date-picker {
  max-width: 170px;
}
.view-application-container .container-box .form-sections .form-inner-section .form-note-container {
  font-size: 16px;
  text-align: left;
  padding: 0;
  margin: 2em 0;
  line-height: 24px;
  width: 100%;
}
.view-application-container .container-box .form-sections .form-inner-section .form-note-container b {
  color: #048041;
}
.view-application-container .container-box .form-sections .form-inner-section .form-note-container ol {
  padding: 1em;
  margin: 0;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row {
  display: flex;
  flex-direction: row;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row.summary-items-header {
  background: #048041;
  color: #FFFFFF;
  min-height: 70px;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details {
  width: 22%;
  text-align: center;
  padding: 1em;
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  justify-content: center;
  font-size: 16px;
  word-break: break-word;
}
@media (max-width: 500px) {
  .view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details {
    font-size: 12px;
  }
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .info-icon {
  padding-left: 1em;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .document-name-input {
  height: 24px;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-action-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-action-btns svg {
  margin: 5px;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-action-btns svg:hover {
  cursor: pointer;
  opacity: 0.7;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-action-btns span {
  font-size: 12px;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .input-container {
  justify-content: center;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .document-name-input {
  position: relative;
  border: 1px solid #048041;
  padding: 0.5em 1em;
  border-radius: 6px;
  font-size: 14px;
  color: #048041;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-container.document-container .summary-items-row .summary-item-details {
  width: 50%;
}
.view-application-container .container-box .form-sections .form-inner-section .empty-summary-container {
  display: flex;
  height: 240px;
  background: #e3ddda;
  width: auto;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 5em;
  text-align: left;
  margin: 1em 0;
  font-family: 'Roboto-Light';
  width: 80%;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .form-inner-section .empty-summary-container {
    padding: 10px;
    width: 95%;
  }
}
.view-application-container .container-box .form-sections .form-inner-section .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #e3ddda;
  margin: 3em 0;
  width: 50%;
}
.view-application-container .container-box .form-sections .form-inner-section.documents-section .input-parent {
  width: 100%;
}
.view-application-container .container-box .form-sections .form-inner-section.documents-section .input-parent .file-upload-container {
  width: 100%;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .form-inner-section.documents-section .input-parent .file-upload-container {
    width: auto;
  }
}
.view-application-container .container-box .form-sections .form-inner-section.documents-section .empty-summary-container {
  background: none;
  border: 1px solid #e3ddda;
}
.view-application-container .container-box .form-sections .form-inner-section.documents-section .summary-items-row .summary-item-details {
  width: 50%;
}
.view-application-container .container-box .form-sections .form-inner-section.documents-section b {
  color: #048041;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container {
  display: flex;
  width: 96%;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin: 1em 0;
  font-size: 16px;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .list-heading {
  font-weight: bold;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container ol li {
  padding: 0.5em 1em;
  line-height: 24px;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container ol li::marker {
  font-weight: bold;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container ol li span {
  color: #048041;
  font-weight: bold;
  text-decoration: underline;
}
@media (max-width: 415px) {
  .view-application-container .container-box .form-sections .form-inner-section .summary-list-container .checkboxes-section .checkbox-list-container .accordion-summary {
    background: none;
  }
  .view-application-container .container-box .form-sections .form-inner-section .summary-list-container .checkboxes-section .checkbox-list-container .accordion-summary p {
    margin: 0;
    font-size: 16px;
  }
  .view-application-container .container-box .form-sections .form-inner-section .summary-list-container .checkboxes-section .checkbox-list-container .MuiPaper-root {
    box-shadow: none;
    border: none;
  }
  .view-application-container .container-box .form-sections .form-inner-section .summary-list-container .checkboxes-section .checkbox-list-container .MuiAccordion-root:before {
    display: none;
  }
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .company-details {
  margin: 1em 0;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .company-details .form-inner-elements .application-form-label {
  color: #048041;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .agreement-text-container {
  margin: 1em 0;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .agreement-text-container .input-parent .error-message {
  padding-left: 0px;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .agreement-text-container .input-box {
  font-family: 'Roboto-Regular';
  font-size: 16px;
  width: 100%;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections .form-inner-section .summary-list-container .agreement-text-container .input-box {
    height: 60px;
    width: auto;
  }
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container .form-declaration-left {
  width: 50%;
  text-align: left;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container .form-declaration-left span {
  border-bottom: 1px solid #048041;
  padding: 10px 20px;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container .form-declaration-right {
  width: 50%;
  text-align: right;
}
.view-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container .form-declaration-right span {
  border-bottom: 1px solid #048041;
  padding: 10px 20px;
}
.view-application-container .container-box .form-sections .form-inner-section .terms-agree-check {
  display: flex;
  font-size: 16px;
  width: 92%;
  align-items: flex-end;
  color: #048041;
}
.view-application-container .container-box .form-sections .form-inner-section .terms-agree-check span {
  margin-right: 10px;
}
.view-application-container .container-box .form-sections.application-summary .form-inner-elements .application-form-label {
  font-weight: bold;
}
.view-application-container .container-box .form-sections.application-summary .form-inner-elements .application-form-element {
  padding: 0 2em;
  justify-content: flex-start;
  font-size: 16px;
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections.application-summary .form-inner-elements .application-form-element {
    padding: 0;
  }
}
@media (max-width: 896px) {
  .view-application-container .container-box .form-sections.facility-summary,
  .view-application-container .container-box .form-sections.documents-section,
  .view-application-container .container-box .form-sections.application-summary,
  .view-application-container .container-box .form-sections.measure-summary {
    min-width: 600px;
  }
}
.view-application-container .information-requested-box {
  margin-bottom: 1em;
}
.view-application-container .information-requested-box .container-box {
  width: auto;
  font-family: 'Roboto-Regular';
  font-size: 16px;
}
.view-application-container .btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2em;
}
