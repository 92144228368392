.options-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
}
.options-container .other-input {
  height: 30px;
  min-width: 200px;
  border-bottom: solid 3px #048041;
  margin-left: 0.5em;
  place-items: center;
  position: relative;
}
@media (max-width: 896px) {
  .options-container .other-input {
    margin-left: 0px;
  }
}
.options-container .other-input input {
  color: #048041;
  appearance: none;
  outline: none;
  border: none;
  font-size: 85%;
  box-shadow: none;
  border-radius: 0px;
  height: 30px;
  padding-bottom: 0px;
  width: 100%;
  position: relative;
  line-height: 30px;
  vertical-align: bottom;
}
.options-container .option-btn {
  background-color: #DBDBDB;
  color: #048041;
  padding-left: 0.4em;
  padding-right: 0.4em;
  margin: 0.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 85%;
  align-items: center;
  border-radius: 6px;
  min-width: 100px;
  height: 30px;
  cursor: pointer;
}
.options-container .option-btn:hover {
  opacity: 0.8;
}
.options-container .option-btn-selected {
  background-color: #048041;
  color: #FFFFFF;
}
.options-container .option-btn-selected:hover {
  opacity: 0.8;
}
