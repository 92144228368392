.search-box {
  display: flex;
  flex-direction: row;
  flex: 100%;
  margin: 10px 0;
}
.search-box .search-input {
  display: flex;
  position: relative;
  border-top: 1px solid #dedede;
  padding: 0.5em 1em;
  border-radius: 5px 0 0 5px;
  font-size: 14px;
  color: #048041;
  flex: 100% 0;
  height: 24px;
  border-bottom: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-right: none;
}
.search-box .search-input:hover,
.search-box .search-input:focus {
  border-bottom: 1px solid #dedede;
  border-left: 1px solid #dedede;
  border-top: 1px solid #dedede;
  outline: none;
  text-decoration: none;
  box-shadow: 0px 0px 5px #048041;
}
.search-box .search-input[disabled] {
  background-color: #E3DDDA;
  box-shadow: none;
  color: #048041;
}
.search-box .search-btn {
  font-family: 'Roboto-Medium';
  background: #048041;
  border: 1px solid #048041;
  color: #FFF;
  border-radius: 0 5px 5px 0;
  padding: 0.5em 1em;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  flex: 0 20%;
  height: 40px;
  justify-content: center;
  align-items: center;
  cursor: unset;
}
