.switch-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto;
}
.switch-container .switch-box {
  display: flex;
  align-items: center;
}
.switch-container .switch-box .switch-value {
  padding-left: 10px;
  font-size: 16px;
  font-family: 'Roboto-Medium';
  min-width: 38px;
}
.switch-container .switch-box .switch-checked {
  color: #048041;
}
.switch-container .switch-box .switch-unchecked {
  color: #DB1900;
}
.switch-container .label-container {
  flex: 1%;
}
.switch-container .label-container p {
  text-align: right;
  padding-right: 3em;
}
