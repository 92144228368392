.internal-header {
  position: fixed;
  width: 100%;
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  z-index: 5;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 80px;
  background-color: #FFFFFF;
}
@media (max-width: 1200px) {
  .internal-header {
    padding: 0;
    background-color: #FFFFFF;
  }
}
.internal-header .header-inner-container {
  max-width: 1366px;
  margin: 0 auto;
  width: 86vw;
  display: flex;
  flex-direction: row;
  position: relative;
}
@media (max-width: 1200px) {
  .internal-header .header-inner-container {
    width: 100vw;
  }
}
.internal-header .header-inner-container nav {
  width: 100%;
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
}
.internal-header .header-inner-container nav .logo {
  padding: 15px 0;
  display: flex;
  height: 60px;
  width: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../images/ESBVectorLogoWithSlogan.svg');
}
@media (min-width: 992px) {
  .internal-header .header-inner-container nav .logo {
    padding: 0;
    height: 80px;
    width: 380px;
  }
}
@media (max-width: 1000px) {
  .internal-header .header-inner-container nav .logo {
    width: 180px;
  }
}
