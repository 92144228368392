.login-wrapper {
  padding-top: 90px;
  padding-bottom: 0px;
  min-height: 80vh;
}
@keyframes backgroundmove {
  100% {
    width: 50%;
    position: fixed;
    height: 81%;
  }
}
.image-container {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: cover;
  left: 0;
  background-image: url('../../../images/SpeedBackground.svg');
}
@media screen and (max-width: 990px) {
  .image-container {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .image-container {
    -webkit-animation-name: backgroundmove;
    animation-name: backgroundmove;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    transition-timing-function: ease-in-out;
  }
}
.image-container .spp-logo {
  width: 250px;
  height: auto;
}
@keyframes slider {
  100% {
    width: 50%;
    position: fixed;
    right: 0;
    visibility: visible;
  }
}
.login-container {
  width: 0%;
  z-index: 0;
  position: fixed;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 0;
  background-color: #FFFFFF;
  min-height: 82vh;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media screen and (max-width: 990px) {
  .login-container {
    width: 100%;
    position: unset;
    visibility: visible;
  }
}
@media screen and (min-width: 992px) {
  .login-container {
    -webkit-animation-name: slider;
    animation-name: slider;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    transition-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
}
.login-container .highlighted-text {
  color: #048041;
}
.login-container .input-parent .error-message {
  padding-left: 0 !important;
}
@media (max-width: 768px) {
  .login-container .input-parent .error-message {
    padding-left: 0 !important;
  }
}
.login-container .input-parent .input-container .input-box {
  min-height: 36px;
  width: 100%;
}
@media (max-width: 896px) {
  .login-container .input-parent .input-container .input-box {
    width: auto;
  }
}
.login-container .input-parent .input-container .input-box .pass-input {
  font-size: 18px;
}
.login-container .login-container::before {
  display: none;
}
.login-container .container-box {
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .login-container .container-box {
    max-width: 350px;
    margin: auto;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .login-container .container-box .section-header {
    padding-top: 2.5em;
    padding-bottom: 1em;
  }
}
.login-container .container-box .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 36px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  margin: auto;
}
.login-container .container-box .form-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 50%;
  width: 400px;
}
@media (max-width: 768px) {
  .login-container .container-box .form-section {
    width: unset;
  }
}
.login-container .container-box .form-section .input-container {
  text-align: right;
  margin-top: 15px;
}
.login-container .container-box .form-section .input-container .input-wrapper {
  width: 100%;
}
@media (max-width: 768px) {
  .login-container .container-box .form-section .input-container {
    text-align: left;
    flex-direction: column;
  }
}
.login-container .container-box .form-section .input-container .input-label {
  font-size: 24px;
  color: #048041;
  width: 120px;
}
@media (max-width: 768px) {
  .login-container .container-box .form-section .input-container .input-label {
    width: unset;
  }
}
.login-container .container-box .form-section .input-container .input-box {
  font-size: 18px;
}
@media (max-width: 768px) {
  .login-container .container-box .form-section .input-container .input-box {
    width: auto;
  }
}
.login-container .container-box .form-section .input-container .password-toggle {
  height: 52px;
}
.login-container .container-box .form-section .login-btn {
  width: 100%;
  margin: 10px auto;
  max-width: 300px;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 75%;
  background-image: url('../../../images/LoginWhite.svg');
}
.login-container .container-box .login-links {
  color: #048041;
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin: 5px 0;
}
.login-container .container-box .login-links .clickable {
  cursor: pointer;
  text-decoration: underline;
}
.login-container .container-box .login-links .clickable:hover {
  opacity: 0.8;
}
.login-container .container-box .login-links a {
  margin-left: 5px;
  font-family: 'Roboto-Medium';
  font-weight: bold;
  cursor: pointer;
}
.login-container .container-box .login-links a:hover {
  opacity: 0.8;
}
