.overviewcard-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1030px;
  min-height: 300px;
  margin-bottom: 10px;
}
.overviewcard-container.account-pending {
  background: rgba(232, 119, 34, 0.25);
}
.overviewcard-container.account-pending-approval {
  background: rgba(177, 228, 227, 0.25);
}
.overviewcard-container.account-participating {
  background: rgba(4, 128, 65, 0.25);
}
.overviewcard-container.account-reviewed {
  background: rgba(177, 228, 227, 0.25);
}
.overviewcard-container.account-verified {
  background: rgba(0, 150, 57, 0.25);
}
.overviewcard-container.account-flagged-for-qa {
  background: rgba(255, 181, 0, 0.25);
}
.overviewcard-container.account-suspended {
  background: rgba(186, 19, 26, 0.25);
}
.overviewcard-container.account-inactive {
  background: rgba(169, 169, 169, 0.25);
}
.overviewcard-container h3 {
  margin: 0.5em 0 0.5em 0.5em;
  font-family: 'Roboto-Medium';
  font-weight: normal;
}
.overviewcard-container h4 {
  font-family: 'Roboto-Medium';
  font-weight: normal;
}
.overviewcard-container .overviewcard-top {
  display: flex;
  align-items: center;
}
@media (max-width: 896px) {
  .overviewcard-container .overviewcard-top h3 {
    font-size: 1.2em;
  }
}
.overviewcard-container .overviewcard-bottom {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}
@media (max-width: 950px) {
  .overviewcard-container .overviewcard-bottom {
    flex-direction: column;
  }
}
.overviewcard-container .overviewcard-bottom .separator {
  height: 120px;
  margin: 0 60px;
}
@media (max-width: 950px) {
  .overviewcard-container .overviewcard-bottom .separator {
    display: none;
  }
}
.overviewcard-container .overviewcard-bottom .overview-details {
  display: flex;
  flex-direction: column;
}
@media (max-width: 896px) {
  .overviewcard-container .overviewcard-bottom .overview-details {
    align-items: center;
    flex: 100%;
  }
}
.overviewcard-container .overviewcard-bottom .overview-details div {
  display: flex;
  padding: 0 0 1.5em 0;
  flex-direction: row;
}
@media (max-width: 896px) {
  .overviewcard-container .overviewcard-bottom .overview-details div {
    width: 100%;
    flex-direction: column;
  }
}
.overviewcard-container .overviewcard-bottom .overview-details div label {
  display: flex;
  font-size: 16px;
  color: #333131;
  font-family: 'Roboto-Medium';
  padding: 0 2em 0 0;
  margin: 0 0 10px 0;
  min-width: 125px;
  align-items: flex-start;
  justify-content: flex-end;
  text-align: right;
}
@media (max-width: 896px) {
  .overviewcard-container .overviewcard-bottom .overview-details div label {
    font-size: 14px;
    width: 100%;
    display: inline-block;
    text-align: left;
    min-width: 130px;
  }
}
.overviewcard-container .overviewcard-bottom .overview-details div span {
  font-family: 'Roboto-Regular';
  font-size: 16px;
  color: #048041;
}
@media (max-width: 896px) {
  .overviewcard-container .overviewcard-bottom .overview-details div span {
    font-size: 16px;
    width: 100%;
    min-width: 130px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}
.overviewcard-container .overviewcard-bottom .overview-details-left {
  align-items: center;
  flex: 50% 0;
  flex-direction: column;
}
.overviewcard-container .overviewcard-bottom .overview-details-left div span {
  min-width: 220px;
  justify-content: flex-start;
}
.overviewcard-container .overviewcard-bottom .overview-details-right {
  align-items: flex-start;
  flex: 0 50%;
}
.overviewcard-container .overviewcard-bottom .overview-details-right div span {
  display: flex;
  min-width: 200px;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 0 5px 0;
}
.overviewcard-container .overviewcard-bottom .overview-details-right .overview-links {
  color: #048041;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  padding: 0 0 0 25px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
}
.overviewcard-container .overviewcard-bottom .overview-details-right .overview-links.change-password-link {
  background-image: url('../../../images/Security.svg');
}
.overviewcard-container .overviewcard-bottom .overview-details-right .overview-links.edit-account-link {
  background-image: url('../../../images/Edit-Icon.svg');
}
