.new-application-container {
  width: 90%;
  max-width: 1024px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.new-application-container .enerva-btn {
  min-width: 300px;
}
.new-application-container .save-icon {
  width: 22px;
  padding-left: 10px;
  cursor: pointer;
}
.new-application-container .save-icon:hover {
  opacity: 0.7;
}
@media (max-width: 896px) {
  .new-application-container .save-icon {
    padding-top: 8px;
  }
}
.new-application-container .agreement-text-area {
  width: 100%;
}
.new-application-container .agreement-text-area input {
  width: 100%;
  background-color: #DBDBDB;
  color: #535765 !important;
  border: none !important;
  border-radius: 2px;
  resize: none;
  font-family: 'Roboto-Medium';
}
.new-application-container .file-upload-prompt {
  text-align: center !important;
  color: #535765 !important;
  font-family: 'Roboto-Light';
  font-size: 16px !important;
}
.new-application-container .file-upload-prompt span {
  color: #535765;
  font-family: 'Roboto-Medium';
}
@media (max-width: 896px) {
  .new-application-container {
    width: 96%;
  }
}
@media (max-width: 500px) {
  .new-application-container .MuiStepLabel-labelContainer .step-label {
    display: none;
  }
}
.new-application-container .step-header-title {
  font-size: 24px;
  margin: 0;
  display: flex;
  text-align: left;
  margin: 20px 0;
  padding: 0 1em;
}
@media (max-width: 896px) {
  .new-application-container .step-header-title {
    align-items: center;
    padding: 0;
  }
}
.new-application-container .update-details {
  background: rgba(197, 232, 108, 0.7);
}
.new-application-container .form-bottom {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 896px) {
  .new-application-container .form-bottom {
    display: flex;
    flex-direction: column;
  }
  .new-application-container .form-bottom span {
    width: 100%;
    margin: 0;
  }
  .new-application-container .form-bottom span .save-btn {
    margin: 0;
  }
}
.new-application-container .form-bottom .document-note-container {
  font-size: 16px;
  text-align: left;
  padding: 0;
  margin: 2em 0;
  line-height: 24px;
  width: 100%;
  flex-direction: row;
  display: flex;
  align-items: flex-end;
}
.new-application-container .form-bottom .document-note-container b {
  color: #048041;
}
.new-application-container .form-bottom .document-note-container .document-note-container-left {
  flex: 75% 0;
}
.new-application-container .form-bottom .document-note-container .document-note-container-right {
  flex: 0 35%;
  justify-content: flex-end;
  display: flex;
}
.new-application-container .form-bottom .save-btn {
  width: 100%;
  margin-top: 15px;
  max-width: 250px;
  text-align: center;
  height: 41px;
}
@media (max-width: 896px) {
  .new-application-container .form-bottom .save-btn {
    max-width: unset;
  }
}
.new-application-container .form-bottom .inversed-btn {
  width: 100%;
  margin-top: 15px;
  max-width: 250px;
  text-align: center;
}
@media (max-width: 896px) {
  .new-application-container .form-bottom .inversed-btn {
    max-width: unset;
  }
}
.new-application-container .back-button {
  appearance: none;
  background: transparent;
  border: none;
  display: block;
  position: absolute;
  left: 0px;
  top: 39px;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 896px) {
  .new-application-container .back-button {
    position: absolute;
    top: 0.4%;
    left: 0;
    padding-top: 1.2em;
  }
}
.new-application-container .back-button img {
  padding-right: 5px;
  width: 6px;
}
@media (max-width: 896px) {
  .new-application-container .step-count {
    position: absolute;
    top: 0.8%;
    right: 0;
  }
}
.new-application-container .section-header {
  padding-top: 2.5em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 896px) {
  .new-application-container .section-header {
    padding-top: 5em;
    padding-bottom: 1em;
  }
}
.new-application-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto-Medium";
  font-weight: normal;
  max-width: 600px;
  margin: auto;
}
.new-application-container .scrollable {
  overflow-y: auto;
  max-height: 300px;
}
.new-application-container .container-box {
  width: 100%;
  box-shadow: 0px 0px 0px #5a71d01b;
  border: 0.5px solid #98989a;
  padding: 1.5em;
  text-align: center;
  text-align: left;
  overflow: overlay;
}
@media (max-width: 896px) {
  .new-application-container .container-box {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 5px;
    border: 0px;
    overflow: hidden;
  }
}
.new-application-container .container-box .agree-to-terms {
  display: flex;
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: #048041;
  margin-top: 1em;
}
.new-application-container .container-box .subtitle {
  color: #048041;
  font-size: 24px;
  text-align: left;
  font-family: 'Roboto-Medium';
}
.new-application-container .container-box .title {
  color: #DBDBDB;
  font-weight: bold;
  text-align: left;
  font-family: 'Roboto-Bold';
  font-size: 18px;
}
.new-application-container .container-box p {
  font-size: 16px;
  text-align: left;
  font-family: 'Roboto-Regular';
}
.new-application-container .container-box .vertical-separator-box {
  display: flex;
  opacity: 0.5;
  flex: 8%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.new-application-container .container-box .vertical-separator-box .separator {
  background-color: #048041;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32%;
  height: 77%;
}
@media (max-width: 896px) {
  .new-application-container .container-box .vertical-separator-box {
    display: none;
  }
}
.new-application-container .container-box .form-sections {
  display: flex;
  flex-direction: column;
  margin: 0 0 3em 0;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections {
    margin: 0;
  }
  .new-application-container .container-box .form-sections .form-note-container {
    margin: 2em 0 0 0 !important;
  }
}
.new-application-container .container-box .form-sections .step-header-container {
  display: flex;
  align-items: center;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .step-header-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.new-application-container .container-box .form-sections .step-header-container .step-header-container-right {
  display: flex;
  align-items: center;
}
.new-application-container .container-box .form-sections .step-header-container .step-header-container-left {
  display: flex;
  align-items: center;
}
.new-application-container .container-box .form-sections .step-header-container .enerva-btn {
  margin: 0;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .step-header-container .enerva-btn {
    margin: 10px 0;
  }
}
.new-application-container .container-box .form-sections .step-header-container .info-icon {
  margin: 0 15px;
  width: 20px;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .step-header-container .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.new-application-container .container-box .form-sections .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #e3ddda;
  margin: 3em 25%;
  width: 50%;
}
.new-application-container .container-box .form-sections .form-inner-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  align-items: center;
}
.new-application-container .container-box .form-sections .form-inner-section .measures-notice {
  padding-top: 1.2em;
}
.new-application-container .container-box .form-sections .form-inner-section .measures-notice span {
  color: #048041;
  font-family: 'Roboto-Medium';
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section {
    width: 100%;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .step-header-title {
  width: 100%;
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements {
  display: flex;
  width: 100%;
  margin: 1em 0;
  align-items: center;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .form-inner-elements {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-label {
  flex: 50% 0;
  text-align: left;
  font-size: 16px;
  padding: 0 2em;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-label {
    margin: 10px 0;
    padding: 0;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element {
  flex: 0 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .legal-name-info {
  position: unset !important;
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .info-icon {
  margin: 0 10px;
  width: 20px;
  position: absolute;
  right: 0;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-container {
  justify-content: flex-end;
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .error-message {
  padding: 5px 0;
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-box {
  width: 240px;
  height: 24px;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-box.enerva-dropdown {
  height: 44px;
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-box.textarea-box {
  width: 240px;
  height: 40px;
}
.new-application-container .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .date-picker {
  max-width: 170px;
}
.new-application-container .container-box .form-sections .form-inner-section .form-note-container {
  font-size: 16px;
  text-align: left;
  padding: 0;
  margin: 2em 0;
  line-height: 24px;
  width: 100%;
}
.new-application-container .container-box .form-sections .form-inner-section .form-note-container b {
  color: #048041;
}
.new-application-container .container-box .form-sections .form-inner-section .form-note-container ol {
  padding: 1em;
  margin: 0;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container {
    border: none;
  }
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container .hide-on-mobile {
    display: none;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row {
  display: flex;
  flex-direction: row;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row {
    flex-direction: column;
    border: solid 1px #F4F4F4;
    margin-bottom: 0.5em;
    padding: 0.2em;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row.summary-items-header {
  background: #048041;
  color: #FFFFFF;
  min-height: 70px;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row.summary-items-header {
    display: none;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details {
  width: 22%;
  text-align: center;
  padding: 1em;
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  justify-content: center;
  font-size: 16px;
  word-break: break-word;
  position: relative;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .info-icon {
  position: absolute;
  top: 8%;
  right: 5%;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .hide-on-mobile {
    display: none;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details label {
  display: none;
  margin-right: 1em;
  width: 100px;
  padding-top: 0.5em;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details label {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    font-family: 'Roboto-Medium';
  }
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details span {
  width: 100%;
  padding-top: 0.5em;
  text-align: center;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details span {
    text-align: left;
  }
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details {
    width: 100%;
    font-size: 12px;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.4em;
    border: none;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .document-name-input {
  height: 24px;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-button-set {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-button-set {
    justify-content: flex-start;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-button-set .summary-action-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-button-set .summary-action-btn {
    margin: 0px;
    margin-right: 10px;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-button-set .summary-action-btn span {
  text-align: center;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-button-set .summary-action-btn svg {
  margin: 5px;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-button-set .summary-action-btn svg:hover {
  cursor: pointer;
  opacity: 0.7;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-button-set .summary-action-btn span {
  font-size: 11.5px;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .input-container {
  justify-content: center;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .document-name-input {
  position: relative;
  border: 1px solid #048041;
  padding: 0.5em 1em;
  border-radius: 6px;
  font-size: 14px;
  color: #048041;
}
.new-application-container .container-box .form-sections .form-inner-section .empty-summary-container {
  display: flex;
  height: 240px;
  background: #e3ddda;
  width: auto;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 5em;
  text-align: left;
  margin: 1em 0;
  font-family: 'Roboto-Regular';
  width: 80%;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .empty-summary-container {
    padding: 10px;
    width: 95%;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #e3ddda;
  margin: 3em 0;
  width: 50%;
}
.new-application-container .container-box .form-sections .form-inner-section.documents-section .input-parent {
  width: 100%;
}
.new-application-container .container-box .form-sections .form-inner-section.documents-section .input-parent .file-upload-container {
  width: 100%;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section.documents-section .input-parent .file-upload-container {
    width: auto;
  }
}
.new-application-container .container-box .form-sections .form-inner-section.documents-section .empty-summary-container {
  border: 1px solid #e3ddda;
}
.new-application-container .container-box .form-sections .form-inner-section.documents-section .summary-items-row .summary-item-details {
  width: 33%;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section.documents-section .summary-items-row .summary-item-details {
    width: 100%;
  }
}
.new-application-container .container-box .form-sections .form-inner-section.documents-section b {
  color: #048041;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container {
  display: flex;
  width: 96%;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin: 1em 0;
  font-size: 16px;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .list-heading {
  font-weight: bold;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container ol li {
  padding: 0.5em 1em;
  line-height: 24px;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container ol li::marker {
  font-weight: bold;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container ol li span {
  color: #048041;
  font-weight: bold;
  text-decoration: underline;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .checkboxes-section .checkbox-list-container .accordion-summary {
  background: none;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .checkboxes-section .checkbox-list-container .accordion-summary p {
  margin: 0;
  font-size: 16px;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .checkboxes-section .checkbox-list-container .MuiPaper-root {
  box-shadow: none;
  border: none;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .checkboxes-section .checkbox-list-container .MuiAccordion-root:before {
  display: none;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .company-details {
  margin: 1em 0;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .company-details .form-inner-elements .application-form-label {
  color: #048041;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .agreement-text-container {
  margin: 1em 0;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .agreement-text-container span {
  color: #048041;
  font-family: 'Roboto-Medium';
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .agreement-text-container .input-parent .error-message {
  padding-left: 0px;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .agreement-text-container .input-box {
  font-family: 'Roboto-Regular';
  font-size: 16px;
  width: 100%;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .summary-list-container .agreement-text-container .input-box {
    height: 60px;
    width: auto;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container {
  display: flex;
  flex-direction: row;
  margin: 1em 0;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container .form-declaration-left {
  width: 50%;
  text-align: left;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container .form-declaration-left span {
  border-bottom: 1px solid #048041;
  padding: 10px 20px;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container .form-declaration-right {
  width: 50%;
  text-align: right;
}
.new-application-container .container-box .form-sections .form-inner-section .summary-list-container .form-declaration-container .form-declaration-right span {
  border-bottom: 1px solid #048041;
  padding: 10px 20px;
}
.new-application-container .container-box .form-sections .form-inner-section .terms-agree-check {
  display: flex;
  font-size: 16px;
  width: 92%;
  align-items: center;
  color: #048041;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections .form-inner-section .terms-agree-check {
    text-align: left;
  }
}
.new-application-container .container-box .form-sections .form-inner-section .terms-agree-check span {
  margin-right: 10px;
}
.new-application-container .container-box .form-sections.application-summary .form-inner-elements .application-form-label {
  font-weight: bold;
}
.new-application-container .container-box .form-sections.application-summary .form-inner-elements .application-form-element {
  padding: 0 2em;
  justify-content: flex-start;
  font-size: 16px;
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections.application-summary .form-inner-elements .application-form-element {
    padding: 0;
  }
}
@media (max-width: 896px) {
  .new-application-container .container-box .form-sections.facility-summary,
  .new-application-container .container-box .form-sections.documents-section,
  .new-application-container .container-box .form-sections.application-summary,
  .new-application-container .container-box .form-sections.measure-summary {
    min-width: 100%;
  }
}
.payeblock-aproval .application-form-element {
  padding: 0 2em !important;
  justify-content: flex-start !important;
  font-size: 16px !important;
}
@media (max-width: 896px) {
  .payeblock-aproval .application-form-element {
    padding: 0 !important;
  }
}
.green-text {
  color: #048041;
  font-family: 'Roboto-Medium';
}
.responsive-table-details {
  position: relative;
}
.responsive-table-details .info-icon {
  position: absolute;
  top: 8%;
  right: 5%;
}
