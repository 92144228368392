@media (max-width: 690px) {
  .ReactModalPortal .ReactModal__Content--after-open {
    max-height: 88% !important;
  }
}
.ReactModalPortal h3 {
  text-transform: uppercase;
  text-align: center;
  margin-top: 0.8em;
  margin-bottom: 0.8em;
  font-family: 'Roboto-Medium';
}
@media (max-width: 768px) {
  .ReactModalPortal h3 {
    font-size: 1em;
  }
}
.ReactModalPortal .error-header {
  color: #DB1900;
}
.ReactModalPortal p {
  font-size: 16px;
  text-align: center;
}
.ReactModalPortal .subtitle {
  color: #048041;
}
.ReactModalPortal .disabled-link {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed !important;
}
.ReactModalPortal .disabled-link:hover {
  cursor: no-drop !important;
}
.ReactModalPortal .logout button {
  flex: 50% 1;
}
.ReactModalPortal .modal-section {
  width: 60%;
  margin: auto;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-section {
    width: 90%;
  }
}
.ReactModalPortal .modal-section img {
  width: 50px;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-section img {
    width: 36px;
  }
}
.ReactModalPortal .modal-section p {
  text-align: left;
  width: 100%;
  padding-left: 2em;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-section p {
    text-align: center;
    line-height: 1.5em;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
    font-size: 1rem !important;
  }
}
.ReactModalPortal .modal-section p a {
  text-align: inherit;
  font-size: inherit;
}
.ReactModalPortal .modal-section a {
  text-decoration: none;
}
.ReactModalPortal .modal-section a:hover {
  opacity: 0.7;
}
.ReactModalPortal .terms-list {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  margin: auto;
  max-width: 550px;
}
.ReactModalPortal .terms-list li {
  text-align: left;
  font-size: 16px;
  font-family: 'Roboto-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReactModalPortal .terms-list li p {
  flex: 80%;
  text-align: left;
}
.ReactModalPortal .terms-list li p span {
  color: #048041;
  text-decoration: underline;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.ReactModalPortal .loading-container {
  height: 100%;
  width: 100%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReactModalPortal .loading-container img {
  width: 70%;
  max-width: 300px;
  animation: spin 3s linear infinite;
}
.ReactModalPortal .close-icon {
  display: none;
}
@media (max-width: 415px) {
  .ReactModalPortal .close-icon {
    display: flex;
    position: absolute;
    left: 96%;
    top: -3%;
    height: 25px;
  }
}
.ReactModalPortal .close-icon svg {
  fill: #048041;
}
.ReactModalPortal .modal-content {
  text-align: center;
  padding: 0 0.5em 0.5em 0.5em;
}
.ReactModalPortal .modal-content .container-box {
  width: auto;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  padding: 1.5em;
  text-align: center;
  text-align: left;
  overflow: auto;
  max-height: 400px;
  margin: 0.6em;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 0.5em;
  }
}
.ReactModalPortal .modal-content .container-box .agree-to-terms {
  display: flex;
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: #048041;
  margin-top: 1em;
}
.ReactModalPortal .modal-content .container-box .subtitle {
  color: #048041;
  font-size: 24px;
  text-align: left;
  font-family: 'Roboto-Medium';
}
.ReactModalPortal .modal-content .container-box .title {
  color: #DBDBDB;
  font-weight: bold;
  text-align: left;
  font-family: 'Roboto-Bold';
  font-size: 18px;
}
.ReactModalPortal .modal-content .container-box p {
  font-size: 18px;
  text-align: left;
  font-family: 'Roboto-Regular';
}
.ReactModalPortal .modal-content .container-box .form-sections {
  display: flex;
  flex-direction: column;
}
.ReactModalPortal .modal-content .container-box .form-sections .step-header-container {
  display: flex;
  align-items: center;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections .step-header-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .step-header-container .step-header-container-right {
  display: flex;
  align-items: center;
}
.ReactModalPortal .modal-content .container-box .form-sections .step-header-container .step-header-container-left {
  display: flex;
  align-items: center;
}
.ReactModalPortal .modal-content .container-box .form-sections .step-header-container .enerva-btn {
  margin: 0;
  max-width: 300px;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections .step-header-container .enerva-btn {
    margin: 10px 0;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .step-header-container .info-icon {
  margin: 0 15px;
  width: 20px;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections .step-header-container .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #E3DDDA;
  margin: 3em 25%;
  width: 50%;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  align-items: center;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections .form-inner-section {
    width: 100%;
    padding-left: 0.1em;
    padding-right: 0.1em;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .step-header-title {
  width: 100%;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements {
  display: flex;
  width: 100%;
  margin: 0.8em 0;
  align-items: center;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-label {
  flex: 50% 0;
  text-align: left;
  font-size: 16px;
  padding: 0 2em;
  font-family: 'Roboto-Medium';
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-label {
    margin: 10px 0;
    padding: 0;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element {
  flex: 0 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .info-icon {
  margin: 0 10px;
  width: 20px;
  position: absolute;
  right: 0;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-container {
  justify-content: flex-end;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .error-message {
  padding: 5px 0;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-box {
  width: 240px;
  height: 24px;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-box.enerva-dropdown {
  height: 44px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .input-box.textarea-box {
  width: 240px;
  height: 40px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-inner-elements .application-form-element .date-picker {
  max-width: 170px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-note-container {
  font-size: 16px;
  text-align: left;
  padding: 0;
  margin: 2em 0;
  line-height: 24px;
  width: 100%;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-note-container b {
  color: #048041;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .form-note-container ol {
  padding: 1em;
  margin: 0;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row {
  display: flex;
  flex-direction: row;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row.summary-items-header {
  background: #048041;
  color: #FFFFFF;
  min-height: 70px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details {
  width: 22%;
  text-align: center;
  padding: 1em;
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  justify-content: center;
  font-size: 16px;
  word-break: break-word;
}
@media (max-width: 500px) {
  .ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details {
    font-size: 12px;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .document-name-input {
  height: 24px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-action-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-action-btns svg {
  margin: 5px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-action-btns svg:hover {
  cursor: pointer;
  opacity: 0.7;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .summary-action-btns span {
  font-size: 12px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .input-container {
  justify-content: center;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container .summary-items-row .summary-item-details .document-name-input {
  position: relative;
  border: 1px solid #048041;
  padding: 0.5em 1em;
  border-radius: 6px;
  font-size: 14px;
  color: #048041;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-container.document-container .summary-items-row .summary-item-details {
  width: 50%;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .empty-summary-container {
  display: flex;
  height: 240px;
  background: #E3DDDA;
  width: auto;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 5em;
  text-align: left;
  margin: 1em 0;
  font-family: 'Roboto-Light';
  width: 80%;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .empty-summary-container {
    padding: 10px;
    width: 95%;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #E3DDDA;
  margin: 3em 0;
  width: 50%;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section.documents-section .input-parent {
  width: 100%;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section.documents-section .input-parent .file-upload-container {
  width: 100%;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections .form-inner-section.documents-section .input-parent .file-upload-container {
    width: auto;
  }
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section.documents-section .empty-summary-container {
  background: none;
  border: 1px solid #E3DDDA;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section.documents-section .summary-items-row .summary-item-details {
  width: 50%;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section.documents-section b {
  color: #048041;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-list-container {
  display: flex;
  width: 96%;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin: 1em 0;
  font-size: 16px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-list-container .list-heading {
  font-weight: bold;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-list-container ol li {
  padding: 0.5em 1em;
  line-height: 24px;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-list-container ol li::marker {
  font-weight: bold;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .summary-list-container ol li span {
  color: #048041;
  font-weight: bold;
  text-decoration: underline;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .terms-agree-check {
  display: flex;
  font-size: 16px;
  width: 92%;
  align-items: flex-end;
  color: #048041;
}
.ReactModalPortal .modal-content .container-box .form-sections .form-inner-section .terms-agree-check span {
  margin-right: 10px;
}
.ReactModalPortal .modal-content .container-box .form-sections.application-summary .form-inner-elements .application-form-label {
  font-family: 'Roboto-Medium';
  font-size: 16px;
}
.ReactModalPortal .modal-content .container-box .form-sections.application-summary .form-inner-elements .application-form-element {
  padding: 0 2em;
  justify-content: flex-start;
  font-size: 16px !important;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections.application-summary .form-inner-elements .application-form-element {
    padding: 0;
  }
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .container-box .form-sections.facility-summary,
  .ReactModalPortal .modal-content .container-box .form-sections.documents-section,
  .ReactModalPortal .modal-content .container-box .form-sections.application-summary,
  .ReactModalPortal .modal-content .container-box .form-sections.measure-summary {
    min-width: 600px;
  }
}
.ReactModalPortal .modal-content .legal-notice-modal-content {
  overflow-y: auto;
}
.ReactModalPortal .modal-content .checkboxes-section .MuiTypography-root p {
  text-align: left !important;
}
.ReactModalPortal .modal-content .requested-info-text {
  max-height: 300px;
  overflow-y: auto;
  padding: 1em;
  text-align: left;
  border: solid 2px #DBDBDB;
}
@media (max-width: 1024px) {
  .ReactModalPortal .modal-content {
    padding: 0;
  }
}
.ReactModalPortal .modal-content .password-inputs {
  max-width: 550px;
  margin-bottom: 2em;
  margin-top: 2em;
}
.ReactModalPortal .modal-content .password-inputs .input-parent .error-message {
  padding-left: 42%;
}
@media (max-width: 768px) {
  .ReactModalPortal .modal-content .password-inputs .input-parent .error-message {
    padding-left: 0%;
  }
}
.ReactModalPortal .modal-content .password-inputs .input-parent .passwordReqs {
  padding-left: 42%;
}
@media (max-width: 768px) {
  .ReactModalPortal .modal-content .password-inputs .input-parent .passwordReqs {
    padding-left: 0%;
  }
}
.ReactModalPortal .modal-content .input-container {
  text-align: right;
  margin-top: 15px;
  display: flex;
}
.ReactModalPortal .modal-content .input-container .input-wrapper {
  width: 100%;
}
@media (max-width: 768px) {
  .ReactModalPortal .modal-content .input-container {
    text-align: left;
    flex-direction: column;
  }
}
.ReactModalPortal .modal-content .input-container .input-label {
  font-size: 18px;
  flex: 63%;
  color: #048041;
}
@media (max-width: 768px) {
  .ReactModalPortal .modal-content .input-container .input-label {
    width: unset;
  }
}
.ReactModalPortal .modal-content .input-container .input-box {
  width: 100%;
  font-size: 18px;
}
@media (max-width: 768px) {
  .ReactModalPortal .modal-content .input-container .input-box {
    width: auto;
  }
}
.ReactModalPortal .modal-content .input-container .password-toggle {
  height: 52px;
}
.ReactModalPortal .modal-content .error-text {
  color: #DB1900;
}
.ReactModalPortal .modal-content .error-text span {
  font-family: 'Roboto-Bold';
  color: #DB1900;
}
.ReactModalPortal .modal-content p {
  font-family: 'Roboto-Regular';
  font-size: 18px;
  color: #535765;
}
.ReactModalPortal .modal-content p span {
  color: #048041;
  font-weight: bold;
}
@media (max-width: 768px) {
  .ReactModalPortal .modal-content p {
    font-size: 12px;
  }
}
.ReactModalPortal .modal-content span {
  font-family: 'Roboto-Medium';
  color: #048041;
}
.ReactModalPortal .modal-content .modal-question {
  font-family: 'Roboto-Medium';
}
.ReactModalPortal .modal-content .button-set {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .button-set {
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 100px;
  }
  .ReactModalPortal .modal-content .button-set button {
    width: 90%;
  }
  .ReactModalPortal .modal-content .button-set a {
    width: 90%;
  }
  .ReactModalPortal .modal-content .button-set a button {
    width: 100%;
    margin: 5px;
  }
}
.ReactModalPortal .ReactModal__Overlay {
  background-color: rgba(83, 87, 101, 0.9) !important;
  z-index: 5 !important;
}
.ReactModalPortal a {
  text-decoration: underline;
}
