.app-confirmation-wrapper-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #CCC;
  padding: 2%;
  max-width: 900px;
  margin: 0 auto;
  width: 90%;
  margin-top: 1em;
}
.app-confirmation-wrapper-1 h3 {
  padding: 0;
  margin: 1em 0;
  color: #048041;
  font-family: 'Roboto-Medium';
  font-weight: normal;
}
@media (max-width: 896px) {
  .app-confirmation-wrapper-1 h3 {
    text-align: center;
  }
}
.app-confirmation-wrapper-1 p {
  font-size: 18px;
  padding: 0 1em;
  font-family: 'Roboto-Regular';
}
.app-confirmation-wrapper-1 p span {
  color: #048041;
  font-weight: bold;
}
.app-confirmation-wrapper-1 .button-set {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 896px) {
  .app-confirmation-wrapper-1 .button-set {
    flex-direction: column;
  }
}
.app-confirmation-wrapper-1 .button-set a {
  max-width: 250px;
}
@media (max-width: 896px) {
  .app-confirmation-wrapper-1 .button-set a {
    width: 80%;
    max-width: unset;
  }
}
.app-confirmation-wrapper-1 .button-set a button {
  max-width: 250px;
}
@media (max-width: 896px) {
  .app-confirmation-wrapper-1 .button-set a button {
    width: 100%;
    max-width: unset;
    margin: auto;
  }
}
.app-confirmation-wrapper-1 .button-set button {
  max-width: 250px;
}
@media (max-width: 896px) {
  .app-confirmation-wrapper-1 .button-set button {
    width: 80%;
    max-width: unset;
  }
}
