@font-face {
  font-family: 'Roboto-Light';
  src: url('../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
.flexHorizontal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 896px) {
  .flexHorizontal {
    flex-direction: column;
  }
}
body {
  font-family: 'Roboto-Regular';
  color: #535765;
  background: #FFFFFF;
  font-size: 14px;
  margin: 0px;
  padding: 0px;
}
::-webkit-scrollbar {
  width: 10px;
}
@media (max-width: 896px) {
  ::-webkit-scrollbar {
    width: 8px;
  }
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.cookie-footer {
  background: #DBDBDB !important;
  min-height: 10vh;
  display: flex;
  position: relative;
  align-items: center !important;
}
@media (max-width: 896px) {
  .cookie-footer {
    flex-direction: row;
  }
}
.cookie-footer div:nth-child(1) {
  flex: 1 0 20% !important;
}
.cookie-footer .enerva-btn {
  font-family: 'Roboto-Medium';
  background: #048041 !important;
  border: 1px solid #FFFFFF;
  color: #FFFFFF !important;
  border-radius: 4px!important;
  padding: 10px 25px!important;
  font-size: 16px;
  font-weight: normal;
}
.cookie-footer p {
  color: #535765;
  font-family: 'Roboto-Regular';
  font-size: 14px;
}
.cookie-footer p span {
  font-size: 12px;
}
.inner-content {
  padding-top: 15vh;
  padding-bottom: 2em;
  min-height: calc(100vh - 250px);
}
@media (max-width: 896px) {
  .inner-content {
    padding-top: 90px;
    padding-bottom: 1.5em;
  }
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
  color: #048041;
}
h3 {
  font-size: 24px;
  color: #048041;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
  color: #048041;
}
a {
  color: #048041;
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
a:active,
a:focus {
  color: #048041;
  outline: none;
  text-decoration: none;
}
button {
  font-family: 'Roboto-Bold';
  cursor: pointer;
  border-radius: 6px;
}
button:hover,
button:active,
button:focus {
  outline: none;
  text-decoration: none;
}
input {
  font-family: 'Roboto-Light';
  border-radius: 6px;
  font-size: 16px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
input:hover,
input:active,
input:focus {
  outline: none;
  text-decoration: none;
}
::placeholder {
  color: #c1c1c4;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c1c1c4;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #c1c1c4;
}
.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}
.middle-container {
  margin: auto;
  width: 80vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}
.secondary-container {
  margin: auto;
  width: 80vw;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.uppercase {
  text-transform: uppercase;
}
.separator {
  border-left: 1px solid #048041;
  min-height: 15px;
  position: relative;
  margin: 0px 20px;
  display: flex;
  height: 100%;
}
.mb15 {
  margin-bottom: 15px;
}
.tooltip {
  position: relative;
}
.tooltip .tooltiptext {
  position: absolute;
  top: -16px;
  min-width: 240px;
  padding: 1em;
  z-index: 100;
  background: #048041;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  left: 35px;
}
.tooltip .tooltiptext a {
  color: #FFF;
  text-decoration: underline;
}
.tooltip .tooltiptext:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #048041;
  left: -7px;
  top: 20px;
}
.enerva-tooltip {
  width: 240px;
  background: #048041 !important;
}
.enerva-tooltip a {
  color: #FFF;
  text-decoration: underline;
}
.empty-summary-container {
  display: flex;
  height: 240px;
  background: #e3ddda;
  width: auto;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 5em;
  text-align: left;
  margin: 1em 0;
  font-family: 'Roboto-Regular';
  width: 80%;
}
@media (max-width: 896px) {
  .empty-summary-container {
    padding: 10px;
    width: 95%;
  }
}
.MuiPickersDay-dayDisabled {
  opacity: 0.4;
}
.button-set .enerva-btn {
  flex: 50% 1;
}
.MuiPopover-root {
  border-radius: 0px;
}
.MuiPopover-root .MuiPaper-rounded {
  border-radius: 0px;
}
.MuiPopover-root .MuiSvgIcon-root {
  width: 1.5em;
  height: 1.5em;
}
.MuiPopover-root .popover-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.MuiPopover-root .popover-buttons .enerva-btn {
  margin: 0px;
  border-radius: 0px;
  background-color: #FFFFFF;
  color: #535765;
  font-family: 'Roboto-Regular';
}
.MuiPopover-root .popover-buttons .enerva-btn:hover {
  font-family: 'Roboto-Medium';
  color: #048041;
  background-color: rgba(0, 150, 57, 0.3);
}
