.MuiStepLabel-labelContainer .step-label {
  color: #048041 !important;
}
.step-container ul {
  padding-left: 0px;
}
.step-container ul li {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border: solid 1px #048041;
  border-radius: 50%;
  background: #048041;
  margin: 0 3em;
  display: inline-block;
  color: white;
  position: relative;
  font-size: 12px;
}
.step-container ul li::before {
  content: '';
  position: absolute;
  top: 0.9em;
  left: -7em;
  width: 8em;
  height: 0.2em;
  background: #048041;
  z-index: -1;
}
.step-container ul li:first-child::before {
  display: none;
}
.step-container ul .active {
  background: #048041;
}
.step-container ul .active ~ li {
  background: #FFFFFF;
  color: #048041;
  border-color: #048041;
}
.step-container ul .active ~ li::before {
  background: #048041;
}
