.legalese-container h3 {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Roboto-Bold';
}
.legalese-container .container-box {
  border: solid 0.5px #535765;
  width: 80vw;
  margin: auto;
  min-height: 600px;
  padding: 1.5em;
}
.legalese-container .container-box p {
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.legalese-container .container-box p strong {
  font-size: 16px;
  text-transform: uppercase;
  font-family: 'Roboto-Medium';
  color: #048041;
}
.legalese-container .container-box p a {
  text-decoration: underline;
}
.legalese-container .update-date {
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.legalese-container .update-date span {
  font-family: 'Roboto-Medium';
}
