@keyframes loadingInput {
  0% {
    transform-origin: 100% 100%;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: 100% 100%;
    transform: rotate(360deg);
  }
}
.inputLoader {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background-color: #DBDBDB;
}
.inputLoader:after {
  position: absolute;
  display: block;
  content: '';
  z-index: 1;
  width: 12px;
  height: 12px;
  top: 20%;
  left: 50%;
  border-radius: 200px 0 0;
  background: linear-gradient(60deg, #048041 0, #048041 50%, #048041 100%);
  animation: loadingInput 1.2s linear infinite;
}
.input-parent .input-note {
  padding-top: 10px;
}
.input-parent .passwordReqs {
  padding-left: 46%;
  list-style: none;
  font-size: 12px;
}
@media (max-width: 896px) {
  .input-parent .passwordReqs {
    padding-left: 0;
  }
}
.input-parent .passwordReqs li {
  text-align: left;
  list-style: none;
}
.input-parent .passwordReqs li .success-icon {
  display: inline-block;
}
.input-parent .passwordReqs li .success-icon img {
  width: 8px;
}
.input-parent .passwordReqs .error-line {
  color: #DB1900;
}
.input-parent .passwordReqs .success-line {
  color: #048041;
}
.input-parent .disable-input-container .quill .ql-editor {
  cursor: not-allowed;
  background-color: #DBDBDB;
}
.input-parent .input-container {
  display: flex;
  position: relative;
  width: 100%;
}
.input-parent .input-container .quill {
  width: 100%;
  margin-bottom: 4em;
}
.input-parent .input-container .quill .ql-picker-label {
  padding-right: 5em;
}
.input-parent .input-container .quill .ql-editor {
  text-align: left;
}
.input-parent .input-container .quill .ql-editor strong {
  font-family: 'Roboto-Medium';
}
.input-parent .input-container .quill .ql-editor h1,
.input-parent .input-container .quill .ql-editor h2,
.input-parent .input-container .quill .ql-editor h3,
.input-parent .input-container .quill .ql-editor h4,
.input-parent .input-container .quill .ql-editor h5 {
  text-align: left;
  font-family: 'Roboto-Regular';
  color: #535765;
}
.input-parent .input-container .quill .ql-editor p {
  text-align: left;
  font-size: 16px;
  color: #535765;
}
.input-parent .input-container .form-sub-heading {
  font-size: 16px;
  display: flex;
  text-align: center;
  font-family: 'Roboto-Medium';
  text-transform: uppercase;
  color: #048041;
  justify-content: center;
  padding: 1em;
  width: auto;
  border-bottom: 1px solid #048041;
  margin: 0 auto;
}
.input-parent .input-container .input-label {
  font-size: 16px;
  margin: 0 0.1em 0 0;
  width: 44%;
  text-align: right;
  padding: 0.5em 1em;
}
@media (max-width: 896px) {
  .input-parent .input-container .input-label {
    width: 100%;
    text-align: left;
    padding-left: 0px;
    font-size: 16px;
  }
}
.input-parent .input-container .input-label .info-icon {
  margin-right: 10px;
}
@media (max-width: 896px) {
  .input-parent .input-container {
    flex-direction: column;
  }
}
.input-parent .input-container .no-border {
  border-color: rgba(0, 0, 0, 0) !important;
}
.input-parent .input-container input[type='password'] {
  padding-right: 2em;
}
.input-parent .input-container .input-box {
  position: relative;
  width: 56%;
  border: 1px solid #048041;
  padding: 0.5em 1em;
  border-radius: 6px;
  font-size: 14px;
  color: #048041;
}
@media (max-width: 896px) {
  .input-parent .input-container .input-box {
    width: unset;
    text-align: left;
  }
}
.input-parent .input-container .input-box:hover,
.input-parent .input-container .input-box:focus {
  border: 1px solid #048041;
  outline: none;
  text-decoration: none;
  box-shadow: 0px 0px 5px #048041;
}
.input-parent .input-container .input-box.disable-input,
.input-parent .input-container .input-box[disabled] {
  background-color: #E3DDDA;
  box-shadow: none;
  color: #048041;
}
.input-parent .input-container .input-box .form-control {
  border: none !important;
  width: 100%;
  height: auto;
  border-radius: 6px;
  height: 100%;
  font-size: 16px;
}
@media (max-width: 896px) {
  .input-parent .input-container .input-box .form-control {
    width: unset;
  }
}
.input-parent .input-container .input-box-pass {
  display: flex;
}
@media (max-width: 896px) {
  .input-parent .input-container .input-box-pass {
    width: unset;
    text-align: left;
  }
}
.input-parent .input-container .input-box-error {
  border: 1px solid #DB1900;
  outline: none;
  text-decoration: none;
  box-shadow: 0px 0px 5px #DB1900;
}
.input-parent .input-container .input-box-error .other-input {
  border-bottom: solid 3px #DB1900;
}
.input-parent .input-container .input-box-success {
  border: 1px solid #048041;
  outline: none;
  text-decoration: none;
  box-shadow: 0px 0px 5px #048041;
}
.input-parent .input-container .react-tel-input {
  padding: 0px !important;
  width: 65.5% !important;
}
.input-parent .input-container .react-tel-input:disabled {
  background-color: #E3DDDA;
  box-shadow: none;
  color: #048041;
}
.input-parent .input-container .react-tel-input.disable-input {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0) !important;
  color: #048041;
}
.input-parent .input-container .react-tel-input.disable-input .form-control {
  background-color: #E3DDDA;
  color: #048041;
}
.input-parent .input-container .react-tel-input.disable-input .form-control:hover,
.input-parent .input-container .react-tel-input.disable-input .form-control:active,
.input-parent .input-container .react-tel-input.disable-input .form-control:focus {
  box-shadow: none;
  cursor: auto;
}
.input-parent .input-container .react-tel-input.disable-input .flag-dropdown {
  background-color: #E3DDDA;
}
@media (max-width: 896px) {
  .input-parent .input-container .react-tel-input {
    width: 100% !important;
    height: 100%;
    min-height: 36px;
  }
}
@media (max-width: 896px) {
  .input-parent .input-container .react-tel-input input {
    width: 100% !important;
    height: 100%;
    min-height: 36px;
  }
}
.input-parent .input-container .react-tel-input .flag-dropdown {
  border-radius: 6px 0px 0px 6px !important;
  border-color: rgba(0, 0, 0, 0) !important;
}
.input-parent .input-container .location-dropdown {
  width: 65.5%;
  cursor: text;
  padding: 0px;
}
@media (max-width: 896px) {
  .input-parent .input-container .location-dropdown {
    width: 100%;
  }
}
.input-parent .input-container .location-dropdown.disable-input {
  border-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
  color: #048041;
}
.input-parent .input-container .location-dropdown.disable-input img {
  display: none;
}
.input-parent .input-container .location-dropdown.disable-input .location-dropdown__control {
  border-color: rgba(0, 0, 0, 0) !important;
  color: #048041;
  background-color: #E3DDDA;
}
.input-parent .input-container .location-dropdown.disable-input .location-dropdown__control .location-dropdown__single-value {
  color: #048041;
}
.input-parent .input-container .location-dropdown .location-dropdown__single-value {
  color: #048041;
}
.input-parent .input-container .location-dropdown .location-dropdown__control {
  cursor: text;
  height: 100%;
  background: #FFFFFF;
  color: #000000;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0);
}
.input-parent .input-container .location-dropdown .location-dropdown__control .location-dropdown__indicators .location-dropdown__indicator-separator {
  display: none;
}
.input-parent .input-container .location-dropdown .location-dropdown__control .location-dropdown__indicators .location-dropdown__indicator svg {
  color: red;
}
.input-parent .input-container .location-dropdown .location-dropdown__control .location-dropdown__value-container {
  padding: 0 1em;
}
.input-parent .input-container .location-dropdown .location-dropdown__control--is-focused {
  outline: none;
  text-decoration: none;
  box-shadow: none;
}
.input-parent .input-container .location-dropdown .location-dropdown__placeholder {
  white-space: nowrap;
  color: #c1c1c4;
}
.input-parent .input-container .location-dropdown .location-dropdown__input {
  caret-color: #048041;
  color: #048041;
}
.input-parent .input-container .location-dropdown .location-dropdown__input input {
  height: 100%;
}
.input-parent .input-container .location-dropdown .location-dropdown__input input:focus {
  box-shadow: none;
}
.input-parent .input-container .enerva-radio {
  width: 65.2%;
}
@media (max-width: 896px) {
  .input-parent .input-container .enerva-radio {
    width: auto;
  }
}
.input-parent .input-container .enerva-dropdown {
  width: 68.5%;
  padding: 0px;
}
@media (max-width: 896px) {
  .input-parent .input-container .enerva-dropdown {
    width: 100%;
  }
}
.input-parent .input-container .enerva-dropdown.disable-input {
  border-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
  color: #048041;
}
.input-parent .input-container .enerva-dropdown.disable-input .enerva-dropdown__control {
  border-color: rgba(0, 0, 0, 0) !important;
  background-color: #E3DDDA;
}
.input-parent .input-container .enerva-dropdown.disable-input .enerva-dropdown__control .enerva-dropdown__single-value {
  color: #048041;
}
.input-parent .input-container .enerva-dropdown.disable-input .enerva-dropdown__control .enerva-dropdown__placeholder {
  color: #535765;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control {
  height: 100%;
  background: #048041;
  border: 1px solid #048041;
  border-radius: 5px;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__indicators .enerva-dropdown__indicator-separator {
  display: none;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__indicators .enerva-dropdown__indicator svg {
  color: #FFF;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__value-container {
  padding: 0 1em;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__single-value {
  color: #FFF;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control--is-focused {
  outline: none;
  text-decoration: none;
  box-shadow: none;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__placeholder {
  white-space: nowrap;
  color: #FFF;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__input {
  caret-color: #FFF;
  color: #FFF;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__menu {
  background: #FFF;
  border: 1px solid #b1e4e3;
  margin: -2px 0 0 0;
  padding: 0;
  border-radius: 0 0 5px 5px;
  box-shadow: none;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__menu .react-select__option:first-child {
  padding: 0;
  margin: 0;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__menu .react-select__option:last-child {
  padding: 0;
  margin: 0;
}
.input-parent .input-container .enerva-multiselect {
  width: 68.5%;
  padding: 0px;
}
@media (max-width: 896px) {
  .input-parent .input-container .enerva-multiselect {
    width: 100%;
  }
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control {
  height: 100%;
  background: #FFFFFF;
  border: none;
  border-radius: 6px;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__indicators .enerva-multiselect__indicator-separator {
  display: none;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__indicators .enerva-multiselect__indicator svg {
  color: #048041;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__multi-value {
  background-color: #048041;
  padding: 0.3em;
  border-radius: 6px;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__multi-value .enerva-multiselect__multi-value__label {
  color: #FFFFFF;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__multi-value .enerva-multiselect__multi-value__remove {
  color: #FFFFFF;
  margin-left: 0.5em;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__multi-value .enerva-multiselect__multi-value__remove:hover {
  color: #FFFFFF;
  cursor: pointer;
  color: #DBDBDB;
  background-color: rgba(0, 0, 0, 0);
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__value-container {
  padding: 5px;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__single-value {
  color: #FFF;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control--is-focused {
  outline: none;
  text-decoration: none;
  box-shadow: none;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__placeholder {
  white-space: nowrap;
  color: #048041;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__input {
  caret-color: #048041;
  color: #048041;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__input input {
  height: 100%;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__input input:focus {
  box-shadow: none;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__menu {
  background: #FFF;
  border: 1px solid #b1e4e3;
  margin: -2px 0 0 0;
  padding: 0;
  border-radius: 0 0 5px 5px;
  box-shadow: none;
  text-align: left;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__menu .react-select__option:first-child {
  padding: 0;
  margin: 0;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__menu .react-select__option:last-child {
  padding: 0;
  margin: 0;
}
.input-parent .error-message {
  font-family: 'Roboto-Light';
  font-size: 12px;
  display: flex;
  padding-left: 46%;
  padding-top: 5px;
  margin-top: 0.15em;
  color: #DB1900;
  text-align: left;
  line-height: 12px;
}
@media (max-width: 896px) {
  .input-parent .error-message {
    padding-left: 0;
  }
}
.input-parent .error-message .error-icon {
  position: relative;
  opacity: 1;
  margin-right: 5px;
}
.input-parent .error-message .error-icon img {
  width: 8px;
}
.input-parent .on-change-text {
  font-size: 12px;
  display: flex;
  padding-left: 46%;
  text-align: left;
  color: #535765;
}
@media (max-width: 896px) {
  .input-parent .on-change-text {
    padding-left: 0;
  }
}
.input-parent .success-message {
  font-size: 12px;
  display: flex;
  padding-left: 46%;
  padding-top: 5px;
  margin-top: 0.15em;
  color: #048041;
  text-align: left;
  line-height: 12px;
}
@media (max-width: 896px) {
  .input-parent .success-message {
    padding-left: 0;
  }
}
.input-parent .success-message .success-icon {
  position: relative;
  opacity: 1;
  margin-right: 5px;
}
.input-parent .success-message .success-icon img {
  width: 8px;
}
