.page-loader {
  flex-direction: column;
  height: 100%;
  min-height: 400px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.page-loader img {
  width: 70%;
  max-width: 300px;
  animation: spin 1.6s linear infinite;
  margin: 20px 0;
}
.page-loader h3 {
  margin: 10px 0;
  font-family: 'Roboto-Light';
  font-weight: normal;
  color: #535765;
}
@media (max-width: 1000px) {
  .page-loader h3 {
    font-size: 18px;
  }
}
.page-loader h3 .highlight-text {
  color: #048041;
  font-weight: bold;
}
