.date-picker {
  display: flex;
  flex-direction: row;
  flex: 100%;
  margin: 0;
  border-bottom: 1px solid #048041;
}
.date-picker fieldset {
  border: none;
}
.date-picker input {
  padding: 5px 0px;
}
.date-picker svg {
  fill: #048041;
}
