.multi-select-checkbox-component {
  display: flex;
  width: auto;
  position: relative;
  margin: 0;
  align-items: center;
}
.multi-select-checkbox-component .multi-select-checkbox-container {
  position: relative;
  display: flex;
  flex: auto;
  height: 45px;
}
.multi-select-checkbox-component .multi-select-checkbox-container .multi-select-checkbox {
  width: 100%;
  height: 45px;
  margin: 0;
  position: absolute;
  left: 0;
  opacity: 0;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 95%;
  background-image: url('../../../images/DropdownArrow.svg');
  background-color: #048041;
  color: #FFFFFF;
  font-family: 'Roboto-Light';
  text-align: left;
  padding: 0 1em;
  border-radius: 4px;
}
.multi-select-checkbox-component .multi-select-checkbox-container .multi-select-checkbox.multi-select-checkbox-selected {
  opacity: 1;
}
.multi-select-checkbox-component .multi-select-checkbox-container .multi-select-checkbox .MuiSelect-icon {
  display: none;
}
.multi-select-checkbox-component .multi-select-checkbox-container .multi-select-checkbox.MuiInput-underline:before {
  display: none;
}
.multi-select-checkbox-component .multi-select-checkbox-container .multi-select-checkbox.MuiInput-underline:after {
  display: none;
}
.multi-select-checkbox-component .multi-select-checkbox-container .multi-select-checkbox.MuiInput-underline:hover:not(.Mui-disabled):before {
  display: none;
}
.multi-select-checkbox-component .multi-select-checkbox-container .multi-select-checkbox .MuiSelect-selectMenu {
  padding-right: 35px;
  font-size: 14px;
}
.multi-select-checkbox-component .multi-select-checkbox-container .multi-select-checkbox-btn {
  width: 100%;
  margin: 0;
  background-size: 12px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 95%;
  background-image: url('../../../images/DropdownArrow.svg');
  font-family: 'Roboto-Light';
  text-align: left;
  text-transform: inherit;
  font-size: 14px;
  height: 45px;
  padding: 0 20px;
}
.MuiMenu-list {
  padding: 0 !important;
}
@media (max-width: 910px) {
  .MuiMenu-list .MuiListItemText-root .MuiTypography-root {
    font-size: 14px;
    white-space: pre-wrap;
  }
}
.multi-select-checkbox-option {
  display: flex !important;
  padding: 8px 12px !important;
}
.multi-select-checkbox-option span {
  font-size: 14px;
}
.multi-select-checkbox-option:hover {
  background-color: #c5e86c !important;
}
.multi-select-checkbox-option.Mui-selected {
  background-color: #c5e86c !important;
  color: #048041;
}
