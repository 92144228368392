.pdf-box-container {
  width: 90%;
  margin: auto;
  height: 700px;
  overflow-y: auto;
}
.pdf-box-container iframe {
  width: 100%;
  height: 100%;
}
