.notfound-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 72vh;
}
@media (max-width: 896px) {
  .notfound-wrapper {
    min-height: 77vh;
  }
}
.notfound-container {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 30vh;
  padding-left: 2em;
  padding-right: 2em;
  background-color: #FFFFFF;
  margin-top: 0px;
  margin-bottom: 0px;
}
@media (max-width: 896px) {
  .notfound-container {
    width: auto;
    margin-left: auto;
    margin-right: auto;
    min-height: 50vh;
  }
}
.notfound-container h2 {
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  text-align: center;
}
.notfound-container p {
  font-size: 18px;
  font-family: 'Roboto-Regular';
  text-align: center;
}
.notfound-container .logo {
  width: 100%;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../../images/ESBVectorLogoWithSlogan.svg');
  background-position: center;
}
@media (max-width: 768px) {
  .notfound-container .logo {
    width: 260px;
    height: 80px;
  }
}
@media (max-width: 1200px) and (min-width: 896px) {
  .notfound-container {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 896px) {
  .notfound-container {
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }
}
.notfound-container .highlighted-text {
  color: #048041;
}
.notfound-container .input-parent .error-message {
  padding-left: 0 !important;
}
@media (max-width: 768px) {
  .notfound-container .input-parent .error-message {
    padding-left: 0 !important;
  }
}
.notfound-container .input-parent .input-container .input-box {
  min-height: 36px;
  width: 100%;
}
@media (max-width: 896px) {
  .notfound-container .input-parent .input-container .input-box {
    width: auto;
  }
}
.notfound-container .input-parent .input-container .input-box .pass-input {
  font-size: 18px;
}
.notfound-container .container-box {
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .notfound-container .container-box {
    max-width: 350px;
    margin: auto;
    padding: 0;
  }
}
@media (max-width: 768px) {
  .notfound-container .container-box .section-header {
    padding-top: 2.5em;
    padding-bottom: 1em;
  }
}
.notfound-container .container-box .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 36px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  margin: auto;
}
.notfound-container .container-box .form-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 50%;
}
.notfound-container .container-box .form-section .input-container {
  text-align: right;
  margin-top: 15px;
}
.notfound-container .container-box .form-section .input-container .input-wrapper {
  width: 100%;
}
@media (max-width: 768px) {
  .notfound-container .container-box .form-section .input-container {
    text-align: left;
    flex-direction: column;
  }
}
.notfound-container .container-box .form-section .input-container .input-label {
  font-size: 24px;
  color: #048041;
  width: 120px;
}
@media (max-width: 768px) {
  .notfound-container .container-box .form-section .input-container .input-label {
    width: unset;
  }
}
.notfound-container .container-box .form-section .input-container .input-box {
  font-size: 18px;
}
@media (max-width: 768px) {
  .notfound-container .container-box .form-section .input-container .input-box {
    width: auto;
  }
}
.notfound-container .container-box .form-section .input-container .password-toggle {
  height: 52px;
}
.notfound-container .container-box .form-section .login-btn {
  width: 100%;
  margin: 10px auto;
  max-width: 300px;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 75%;
  background-image: url('../../../images/LoginWhite.svg');
}
.notfound-container .container-box .login-links {
  color: #048041;
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin: 5px 0;
}
.notfound-container .container-box .login-links .clickable {
  cursor: pointer;
  text-decoration: underline;
}
.notfound-container .container-box .login-links .clickable:hover {
  opacity: 0.8;
}
.notfound-container .container-box .login-links a {
  margin-left: 5px;
  font-family: 'Roboto-Bold';
  font-weight: bold;
  cursor: pointer;
}
.notfound-container .container-box .login-links a:hover {
  opacity: 0.8;
}
