/*******************************/
/********** Header CSS *********/
/*******************************/
.header {
  position: fixed;
  width: 100%;
  padding: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 3px 6px #00000029;
  min-height: 80px;
  background-color: #FFFFFF;
  z-index: 5;
}
@media (max-width: 1200px) {
  .header {
    padding: 0;
  }
}
.header .header-inner-container {
  max-width: 1366px;
  margin: 0 auto;
  width: 80vw;
  display: flex;
  flex-direction: row;
  position: relative;
}
@media (max-width: 1200px) {
  .header .header-inner-container {
    width: 100vw;
  }
}
.header .header-inner-container nav {
  width: 100%;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .header-inner-container nav .nav-ul li {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 16px;
  color: #98989A;
}
.header .header-inner-container nav .nav-ul li a {
  color: #98989A;
}
.header .header-inner-container nav .nav-ul li:hover {
  opacity: 0.7;
}
.header .header-inner-container nav .menu-expand li {
  font-size: 18px;
  color: #FFFFFF;
  text-align: center;
}
.header .header-inner-container nav .menu-expand li a {
  color: #FFFFFF;
}
.header .header-inner-container nav .logo {
  padding: 15px 0;
  display: flex;
  height: 60px;
  width: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../images/ESBVectorLogoWithSlogan.svg');
}
@media screen and (min-width: 992px) {
  .header .header-inner-container nav .logo {
    padding: 0;
    height: 80px;
    width: 380px;
  }
}
