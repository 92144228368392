.verified-email-container {
  max-width: 1030px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  min-height: 70vh;
}
.verified-email-container .verified-box {
  width: 90%;
  box-shadow: 0px 0px 5px #98989A;
  max-width: 680px;
  margin: auto;
  text-align: center;
  border: solid #98989A 0.25px;
  display: flex;
  flex-direction: column;
  min-height: 330px;
  justify-content: center;
}
.verified-email-container .verified-box .verified-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
}
.verified-email-container .verified-box .verified-content .password-inputs {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 180px;
  width: 65%;
  padding-top: 1em;
  padding-bottom: 1em;
}
.verified-email-container .verified-box .verified-content .password-inputs .input-parent .success-message {
  padding-left: 38%;
}
.verified-email-container .verified-box .verified-content .password-inputs .input-parent .error-message {
  padding-left: 38%;
}
.verified-email-container .verified-box .verified-content .password-inputs .input-parent .passworReqs {
  padding-left: 38%;
}
.verified-email-container .verified-box .verified-content .password-inputs .input-parent .input-container .input-label {
  width: 30%;
}
@media (max-width: 896px) {
  .verified-email-container .verified-box .verified-content .password-inputs .input-parent .input-container .input-label {
    width: 100%;
  }
}
@media (max-width: 896px) {
  .verified-email-container .verified-box .verified-content .password-inputs .enerva-btn {
    width: 100%;
    margin-top: 1em;
    margin-left: 0px;
    margin-right: 0px;
  }
}
.verified-email-container .verified-box .verified-content p {
  width: 80%;
  margin: auto;
}
@media (max-width: 896px) {
  .verified-email-container .verified-box .verified-content p {
    width: 90%;
  }
}
.verified-email-container .verified-box .verified-content .title-label {
  color: #048041;
  text-transform: uppercase;
  font-family: 'Roboto-Bold';
  font-size: 24px;
}
.verified-email-container .verified-box .verified-content .button-container {
  display: flex;
  margin-top: 2em;
  width: 80%;
  margin: auto;
  justify-content: space-between;
}
@media (max-width: 896px) {
  .verified-email-container .verified-box .verified-content .button-container {
    flex-direction: column;
    width: 100%;
  }
}
.verified-email-container .verified-box .verified-content .subtitle-message {
  color: #048041;
  text-align: center;
  font-family: 'Roboto-Regular';
  font-size: 18px;
  text-transform: uppercase;
}
.verified-email-container .verified-box .verified-content .basic-message {
  color: #98989A;
  text-align: center;
  font-family: 'Roboto-Light';
  font-size: 18px;
}
.verified-email-container .verified-box .verified-content .btn-label {
  color: #98989A;
  text-align: center;
  font-family: 'Roboto-Regular';
  font-size: 18px;
  flex: 50%;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.verified-email-container .verified-box .loading-container {
  height: 100%;
  width: 100%;
  min-height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.verified-email-container .verified-box .loading-container img {
  width: 70%;
  max-width: 300px;
  animation: spin 3s linear infinite;
}
